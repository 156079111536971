
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return {
      doctors: [],
      noDoctor: true
    };
  },
  methods: {
    onViewProfileClick(docId) {
      var doctor = this.doctors.find(item => {
        return item["id"] === docId;
      });

      if (doctor) {
        this.$f7.views.main.router.navigate("/doctorsProfile/" + doctor["id"]);
      } else {
        console.log("Doctor not found.");
      }
    },

    onRequestSessionClick(docId) {
      var doctor = this.doctors.find(item => {
        return item["id"] === docId;
      });

      if (doctor) {
        this.$f7.views.main.router.navigate("/teleconsult/requestsession", {
          context: doctor
        });
      } else {
        console.log("Doctor not found.");
      }
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$f7.dialog.preloader();
      $this.$f7.request.get($this.$app.data["endpoint"] + "/services/experts", (data, status, xhr) => {
        $this.$f7.dialog.close();
        $this["doctors"] = data["status"] === "success" ? data["result"] || [] : [];
        $this["noDoctor"] = !$this["doctors"].length ? true : null;
        $this.$update();
      }, (xhr, status) => {
        $this.$f7.dialog.close();
      }, "json");
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '2fe9ffa799',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Experts</div></div></div><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class=container><div class="doc-search-main main-content"><div class=doc-search-content><div class=top-card-info style="display: none;"><div class="card mr-0"><div class="card-content card-content-padding"><h4 class=mr-0>Header</h4><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a diam quam. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed id pharetra justo, non aliquam leo...</p><p class=text-align-right><a href=#>Know more</a></p></div></div></div><div class=doc-search-top style="display: none;"><ul class="pd-0 menu list-inline"><li><a href=#>Symptoms</a></li><li><a href=#>Treatment</a></li><li><a href=#>How it Works</a></li><li><a href=#>Benefits</a></li></ul><!-- Searchbar with auto init --><div class="mt-2 filter-search"><form action=""><div class="item-content item-input"><div class=item-inner><div class=item-media><i class=f7-icons style="color: #777777;">search</i></div><div class=item-input-wrap><input type=url placeholder="Search by name"></div><div class=filter-icon><a class=sheet-open href=# data-sheet=.my-sheet><img src=./static/filter_icon.svg class="css-121eyt-SearchIcon e10efg1y0"></a></div></div></div></form></div></div><div class=doc-search-inner><div class="block no-margin">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.noDoctor, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<p class="mt-2 text-align-center">No expert found.</p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.ProfilePic, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.Speciality, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.Duration, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.Fee, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list no-hairlines no-hairlines-between media-list no-margin"><ul class="bg-none row">';
          r += Template7Helpers.each.call(ctx_2, ctx_2.doctors, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="col-100 medium-50 large-50"><div class="card no-shadow doctor-profile-card"><div class=doc-prfl-card-header><div class=card-header><a href=/doctorsProfile/';
              r += c(ctx_3.id, ctx_3);
              r += ' class="item-link item-content"><!-- <a @click="onViewProfileClick(\'';
              r += c(ctx_3.id, ctx_3);
              r += '\')" class="item-link item-content"> --><div class=item-media><div class="doc-det-left elevation-3"><div class=doc-avtar>';
              r += Template7Helpers.if.call(ctx_3, ctx_3.ProfilePic, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <img src=';
                  r += c(ctx_4.ProfilePic, ctx_4);
                  r += ' class=img-fluid alt=""> ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <img src=./static/DefaultProfile.png class=img-fluid alt=""> ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></div><div class=item-inner><div class=doc-prfl-det><div class=item-title-row><div class=item-title>';
              r += c(ctx_3.Salutation, ctx_3);
              r += ' ';
              r += c(ctx_3.Name, ctx_3);
              r += '</div></div><div class="item-subtitle f-12">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.Speciality, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.Speciality, ctx_4);
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<p class=doc-ava>';
              r += c(ctx_3.Qualification, ctx_3);
              r += '</p></div></div></div></a></div><div class="card-content card-content-padding"><div class="doctor-ava-detials mr-0"><div class=row><div class=col-100><p class="doc-ava mr-0"><i class=f7-icons>bubble_right</i> ';
              r += c(ctx_3.Languages, ctx_3);
              r += ' mins</p></div>';
              r += Template7Helpers.if.call(ctx_3, ctx_3.Duration, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=col-50><p class=doc-ava><i class=f7-icons>clock</i> ';
                  r += c(ctx_4.Duration, ctx_4);
                  r += ' mins</p></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.Fee, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="col-50 text-align-right"><p class=doc-fees>';
                  r += c(ctx_4.Symbol, ctx_4);
                  r += ' <span>';
                  r += c(ctx_4.Fee, ctx_4);
                  r += '</span></p></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></div></div><div class="doc-prfl-card-footer text-align-center"><a href=/teleconsult/requestsession/';
              r += c(ctx_3.id, ctx_3);
              r += ' class="button button-raised">Request Session</a></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    