import $$ from 'dom7';
import $ from 'jquery';
import validate from 'jquery-validation';
import intlTelInput from 'intl-tel-input';
export default {
  data: function () {
    var userobj = {};
    return userobj;
  },
  methods: {
    onNextClick(e) {
      e.preventDefault();
      var $this = this;
      if (!$(".mobile-form").valid()) return false;
      var mobile = $this.$el.find(".mobile-box").val().trim();
      if (!mobile) return $this.$f7.dialog.alert("Please enter mobile no.");
      $this["userobj"]["DialCode"] = "+91";
      $this["userobj"]["PhoneNo"] = mobile;
      $this.$f7.dialog.preloader();
      $this.$f7.request.post($this.$app.data["endpoint"] + "/users/login/sendotp", {
        DialCode: "+91",
        PhoneNo: mobile
      }, (data, status, xhr) => {
        $this.$f7.dialog.close();

        if (data && data["status"] === "success") {
          $this.$router.navigate("/verifyotp/", {
            context: $this["userobj"]
          });
        } else {
          $this.$f7.dialog.alert("Unable to send OTP. Please try again.");
        }
      }, (xhr, status) => {
        $this.$f7.dialog.close();
        $this.$f7.dialog.alert("Unable to send OTP. Please try again.");
      }, "json");
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this; // $this.$f7.dialog.preloader();                

      const input = document.querySelector(".mobile");
      $this.iti = intlTelInput(input, {
        placeholderNumberType: true,
        separateDialCode: true,
        preferredCountries: ["in", "gb", "us", "ne"]
      });
      $.validator.addMethod("regex", function (value, element, regexpr) {
        return regexpr.test(value);
      }, "Incorrect mobile number.");
      $(".mobile-form").validate({
        rules: {
          Mobile: {
            required: true,
            minlength: 10,
            maxlength: 10,
            regex: /^[0-9]*$/
          }
        },
        messages: {
          Mobile: {
            required: "Enter mobile number.",
            minlength: "Incorrect mobile number.",
            maxlength: "Incorrect mobile number.",
            regex: "Invalid mobile number."
          }
        },
        errorPlacement: function (error, element) {
          if (element.attr("name") == "Mobile") //Id of input field
            error.appendTo('.mobile-error');
        },
        submitHandler: function (form) {
          return false;
        }
      });
      $this["userobj"] = page.route.context;
      $this.$update();
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '014862b53e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class=page-content><div class=pattern-bg-fixed><div class=login-page><div class=header-title><h2 class=font-alternate>Update Mobile</h2></div><div class=container><div class="block no-margin"><div class="login-title text-align-left" style="padding-left: 20px"><h2 class=tenant_name>Hello! ';
      r += c(ctx_1.Name, ctx_1);
      r += '</h2><p class="tenant_slogan font-alternate"><span>Please update your mobile number for better experience.</span></p></div><!-- onsubmit="return false;" --><form class=mobile-form><div class="list no-hairlines"><ul class=bg-none><!-- <li class="item-content">\r\n                                            <div class="welcome-header" style="font-size: 20px;font-weight: 500;">Login <span class="welcome-header-small" style="font-size: 16px;font-weight: 400;color: #535766;">or</span> Signup</div>\r\n                                        </li> --><li class="item-content item-input item-input-outline"><div class="item-inner login-input"><div class="item-title item-floating-label">Mobile Number</div><div class=item-input-wrap><input type=tel class="mobile-box mobile" name=Mobile placeholder="" autofocus></div><div class="mobile-error error-msg"></div></div></li></ul></div><div class=sign-in-btn><!-- @click="onNextClick" --> <button type=submit class="button button-fill button-round" @click=onNextClick>Next</button></div></form></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};