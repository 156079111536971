
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {// return this.$root["user"];
  },
  methods: {
    onLogoutClick(e) {
      var $this = this;
      $this.$root.onLogoutClick((err, isLogout) => {
        if (isLogout) {
          localStorage.removeItem("loginObj");
        } else {
          $this.$f7.dialog.alert(err.email + "[" + err.code + "]: " + err.message + " Please try again...");
        }
      });
    },

    onLoginClick(e) {
      var $this = this;
      $this.$app.views.main.router.navigate("/login/", {
        force: true
      });
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$update();
    },

    pageReInit(e, page) {
      this.$update();
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '21c11f2be9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page dashboard-page-bg" data-name=home><!-- Top Navbar --><div class=top-bar><div class=container><div class=navbar><!-- <div class="navbar-bg"></div> --><div class=navbar-inner><div class=main-logo><a href=# class=link><img src=../static/main-logo.png alt=Smyl style="max-width: 100%; height: auto;"></a></div><div class=right style="margin-right: 10px;">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root["user"], {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a class="title sliding" href=/profile/ >';
          r += Template7Helpers.if.call(ctx_2, ctx_2.$root["user"]["Salutation"], {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.$root["user"]["Salutation"], ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.$root["user"]["Name"], ctx_2);
          r += '</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root["user"], {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- <a href="#" class="link icon-only panel-open" data-panel="left">\r\n                  <i class="icon f7-icons if-not-md">menu</i>\r\n                  <i class="icon material-icons if-md">menu</i>\r\n                </a> --> <a class="link icon-only" @click=onLogoutClick><i class="icon f7-icons if-not-md">power</i> <i class="icon material-icons if-md">power_settings_new</i> </a>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a class=link @click=onLoginClick><span class=material-symbols-outlined>Login </span></a>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><!-- <div class="title-large mt-1">\r\n              <div class="title-large-text">Smyl App</div>\r\n            </div> --></div></div></div></div><!-- Scrollable page content--><div class=page-content><!-- <div class="block">\r\n        <p>Keep smyling, Always!</p>\r\n      </div> --><div class=container><div class="home-content-block text-align-center" style="padding-bottom: 5rem;"><div class="row no-gap"><!-- <div class="col-50 medium-33 large-33">\r\n              <a href="/profile/" class="menu-box profile-menu-box">\r\n                <div class="dashboard-img profile-img"></div>                  \r\n                <div class="dashboard-content-title">Profile</div>\r\n              </a>\r\n            </div> --><!-- <div class="col-50 medium-33 large-33">\r\n              <a href="/teleconsult/requests" class="menu-box records-box">            \r\n                <div class="dashboard-img records-img"></div> \r\n                <div class="dashboard-content-title">Request</div>\r\n              </a>        \r\n            </div> --> ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.user, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="col-50 medium-33 large-33"><a href=/requestsession/ class="menu-box ask-expert-box"><div class="dashboard-img ask-expert-img"></div><div class=dashboard-content-title>Ask Expert</div></a></div><div class="col-50 medium-33 large-33"><a href=/teleconsult/home class="menu-box tele-consult-box"><div class="dashboard-img tele-consult-img"></div><div class=dashboard-content-title>Tele-Consult</div></a></div><div class="col-50 medium-33 large-33"><a href=/selfassessment class="menu-box self-assessment-box"><div class="dashboard-img self-assessment-img"></div><div class=dashboard-content-title>Self Assessment</div></a></div><div class="col-50 medium-33 large-33"><a href=/contents class="menu-box records-box content-text-box"><div class="dashboard-img content-img"></div><div class=dashboard-content-title>Content</div></a></div><div class="col-50 medium-33 large-33"><a href=/activity/sleep class="menu-box sleep-tracker-box"><div class="dashboard-img sleep-tracker-img"></div><div class=dashboard-content-title>Sleep Tracker</div></a></div><div class="col-50 medium-33 large-33"><a href=/activity/sleep/analytics class="menu-box sleep-analytics-box"><div class="dashboard-img sleep-analytics-img"></div><div class=dashboard-content-title>Sleep Analytics</div></a></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- <div class="col-50 medium-33 large-33">\r\n              <a class="menu-box ask-expert-box">           \r\n                <div class="dashboard-img ask-expert-img"></div>                  \r\n                <div class="dashboard-content-title">Ask Expert</div>\r\n              </a>\r\n          </div>\r\n          <div class="col-50 medium-33 large-33">\r\n            <a class="menu-box tele-consult-box">\r\n              <div class="dashboard-img tele-consult-img"></div>\r\n              <div class="dashboard-content-title">Tele-Consult</div>\r\n            </a>\r\n          </div> --><div class="col-50 medium-33 large-33"><a href=/selfassessment class="menu-box self-assessment-box"><div class="dashboard-img self-assessment-img"></div><div class=dashboard-content-title>Self Assessment</div></a></div><div class="col-50 medium-33 large-33"><a href=/contents class="menu-box records-box content-text-box"><div class="dashboard-img content-img"></div><div class=dashboard-content-title>Content</div></a></div><div class="col-50 medium-33 large-33"><a href=/activity/sleep class="menu-box sleep-tracker-box"><div class="dashboard-img sleep-tracker-img"></div><div class=dashboard-content-title>Sleep Tracker</div></a></div><!-- <div class="col-50 medium-33 large-33">\r\n            <a class="menu-box sleep-analytics-box">            \r\n              <div class="dashboard-img sleep-analytics-img"></div>                  \r\n              <div class="dashboard-content-title">Sleep Analytics</div>\r\n            </a>        \r\n          </div> --> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<!-- <div class="col-50 medium-33 large-33">\r\n              <a href="/issuedomain" class="menu-box issue-domain-box">            \r\n                <div class="dashboard-img issue-domain-img"></div>                  \r\n                <div class="dashboard-content-title">Issue Domain</div>\r\n              </a>\r\n            </div> --><!-- <div class="list">\r\n            <ul>\r\n              <li>\r\n                <a href="/about/" class="item-content item-link">\r\n                  <div class="item-inner">\r\n                    <div class="item-title">About</div>\r\n                  </div>\r\n                </a>\r\n              </li>\r\n              <li>\r\n                <a href="/form/" class="item-content item-link">\r\n                  <div class="item-inner">\r\n                    <div class="item-title">Form</div>\r\n                  </div>\r\n                </a>\r\n              </li>\r\n            </ul>\r\n          </div> --></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    