export default {
  id: 'b96f6674dc',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Moxie Emotional</div></div></div><div class="page-content m-0"><!-- <div class="bg-layer"></div>     --><div class=pattern-bg-fixed><div class=forgot-password-inner><div class=container><div class="row justify-content-center"><div class="col-100 large-70"><div class="block no-margin card-outer"><div class="card mob-card-none bg-none no-margin"><div class="card-content card-content-padding"><form><h2 class=text-align-center>Forgot Password</h2><div class=list><ul><li class="item-content item-input item-input-outline"><div class="item-inner login-input"><div class="item-title item-floating-label">Mobile or Email</div><div class=item-input-wrap><input type=text placeholder=""></div></div></li></ul></div><div class=list><div class="block-footer mt-1"><button class="button button-fill button-round button-200" style="background-color: #eb8e86;">Change</button></div></div></form></div></div></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};