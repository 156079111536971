export default {
  id: '9c3c7a4c21',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Recommended Activities</div></div></div><div class=page-content><div class=pattern-bg-fixed><div class="goal-routine main-content"><div class="heading text-align-center"><h3 class=no-margin>Your personalised plan is ready!</h3></div><div class=container><div class="row justify-content-center"><div class="col-100 large-70"><div class="block no-margin"><p class="bold text-align-center">Follow this routine everyday</p><div class="list no-hairlines no-hairlines-between"><ul class=bg-none><li><div class=item-content><div class=item-media><div class=f7-demo-icon><i class=f7-icons>person_alt_circle</i></div></div><div class=item-inner>An activity to keep anxiety at day</div></div></li><li><div class=item-content><div class=item-media><div class=f7-demo-icon><i class=f7-icons>person_alt_circle</i></div></div><div class=item-inner>Goals to help you build healthy habits</div></div></li><li><div class=item-content><div class=item-media><div class=f7-demo-icon><i class=f7-icons>person_alt_circle</i></div></div><div class=item-inner>An anxiety tracker with specialised insights</div></div></li><li><div class=item-content><div class=item-media><div class=f7-demo-icon><i class=f7-icons>person_alt_circle</i></div></div><div class=item-inner>Resources to better understand your anxiety</div></div></li></ul></div><hr><p class="margin-top text-align-center txt">All we need from you is to be here everyday, and to have some patience. Change takes time and requires effort. But in the end, it will be worth it.</p><div class=block><button class="button button-fill button-round button-raised button-200 button-pink">Go to today\'s plan</button></div></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};