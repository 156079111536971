export default {
  data: function () {
    var productId = this.$route.params.id;
    var currentProduct;
    this.$root.products.forEach(function (product) {
      if (product.id === productId) {
        currentProduct = product;
      }
    });
    return {
      product: currentProduct
    };
  },
  id: 'a8c8322c29',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=product><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>';
      r += c(ctx_1.product.title, ctx_1);
      r += '</div></div></div><div class=page-content><div class=block-title>About ';
      r += c(ctx_1.product.title, ctx_1);
      r += '</div><div class="block block-strong">';
      r += c(ctx_1.product.description, ctx_1);
      r += '</div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};