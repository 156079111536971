import SplashPage from '../pages/splash.f7.html';
import SplashNewPage from '../pages/splash-second.f7.html';
import WelcomePage from '../pages/welcome.f7.html';
import IssueDominAreaPage from '../pages/issue-domin-area.f7.html';
import IssueDominAreaFirstPage from '../pages/issue-domain-first.f7.html';
import IssueDominAreaSecondPage from '../pages/issue-domain-second.f7.html';
import PdsqFirstPage from '../pages/pdsq-first.f7.html';
import PdsqSecondPage from '../pages/pdsq-second.f7.html';
import PdsqThirdPage from '../pages/pdsq-third.f7.html';
import AssessStartPage from '../pages/self-assess-start.f7.html';
import AssessQuestionPage from '../pages/self-assess-question.f7.html';
import AssessSubQuestionPage from '../pages/self-assess-subquestion.f7.html';
import LoginPage from '../pages/login.f7.html';
import VerifyOTPPage from '../pages/verify-otp.f7.html';
import UpdateMobilePage from '../pages/update-mobile.f7.html';
import ProfilePage from '../pages/profile.f7.html';
import SignupPage from '../pages/sign-up.f7.html';
import ChangePasswordPage from '../pages/change-password.f7.html';
import ForgotPasswordPage from '../pages/forgot-password.f7.html';
import RequestExpertSessionPage from '../pages/request-expert-session.f7.html';
import DoctorsSearchPage from '../pages/doctors-search.f7.html';
import DoctorsProfilePage from '../pages/doctors-profile.f7.html';
import DoctorsProfileNewPage from '../pages/doctors-profile-1.f7.html';
import ContentPage from '../pages/contents.f7.html';
import RelativeContentPage from '../pages/relative-contents.f7.html';
import RelativeExpertPage from '../pages/relative-experts.f7.html';
import ArticlePage from '../pages/article.f7.html';
import ArticleBackupPage from '../pages/article-backup.f7.html';
import SelfAssessmentPage from '../pages/self-assessment.f7.html';
import SelfAssessmentResultPage from '../pages/self-assessment-result.f7.html';
import SelfAssessmentResultLavelPage from '../pages/self-assessment-result-lavel.f7.html';
import AssessmentRecordsPage from '../pages/assessment-records.f7.html';
import ExpertAvailabilityPage from '../pages/expert-availability.f7.html'; 
import ExpertAvailabilityPaymentPage from '../pages/expert-availability-payment.f7.html';
import ExpertFailedTransactionPage from '../pages/expert-availability-transaction-failed.f7.html';
import ExpertPaymentConfirmationPage from '../pages/expert-payment-booking-confirmation.f7.html';
import ConsultationRecordsPage from '../pages/consultation-records.f7.html';
import GoalSettingPage from '../pages/goal-setting.f7.html';
import GoalSettingFirstPage from '../pages/goal-setting-first.f7.html';
import GoalSettingSecondPage from '../pages/goal-setting-second.f7.html';
import SubscriptionPlanPage from '../pages/subscription-plan.f7.html';
import SubscriptionPlanFeaturesPage from '../pages/subscription-plan-features.f7.html';
import PlanPackagePage from '../pages/plan-package.f7.html';
import PlanPackageDetailsPage from '../pages/plan-package-details.f7.html';
import ActivitiesTrackingPage from '../pages/activities-tracking.f7.html';
import ActivitiesTrackingMoodPage from '../pages/activities-tracking-mood.f7.html';
import ActivitiesTrackingSleepPage from '../pages/activities-tracking-sleep.f7.html';
import ActivitiesTrackingJournalPage from '../pages/activities-tracking-journal.f7.html';
import ActivitiyPlanProgressPage from '../pages/activity-plan-progress.f7.html';
import SleepTrackerPage from '../pages/sleep-tracker.f7.html';
import SleepTrackerResultPage from '../pages/sleep-tracker-result.f7.html';
import SleepTrackerAnalyticsPage from '../pages/sleep-analytics.f7.html';
import PhysicalTrackerPage from '../pages/physical-tracking.f7.html';

// Tele-Consult Pages //
import SessionPage from '../pages/session-home.f7.html';
import RequestSessionPage from '../pages/request-session.f7.html';
import RequestListPage from '../pages/request-list.f7.html';
import BookSession from '../pages/session-book.f7.html';
import EditSession from '../pages/session-edit.f7.html';
import EditSessionRequest from '../pages/session-edit-request.f7.html';
import VideoConsult from '../pages/video-chat.f7.html';
import TextConsult from '../pages/text-chat.f7.html';
import TeleRecord from '../pages/tele-record.f7.html';

import HomePage from '../pages/home.f7.html';
import DocHomePage from '../pages/doctor-home.f7.html';
import AsstHomePage from '../pages/assistant-home.f7.html';
import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';
import ScreensPage from '../pages/screens.f7.html';
import ProductPage from '../pages/product.f7.html';
import SettingsPage from '../pages/settings.f7.html';
import UserDashboardPage from  '../pages/user-dashboard.f7.html';

import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import AttendantViewPage from '../pages/attendant-view.f7.html';
import NotFoundPage from '../pages/404.f7.html';
import PDSQAnalyticsPage from '../pages/pdsq-analytics.f7.html';
import AssessmentAnalyticsPage from '../pages/assessment-analytics.f7.html';
import RescheduleAppointmentPage from '../pages/reschedule-appointment.f7.html';
import SuccessMessagePage from '../pages/success-message.f7.html';

var routes = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/home1',
    component: UserDashboardPage,
  },
  {
    path: '/attendantView',
    component: AttendantViewPage,
  },
  {
    path: '/dochome',
    component: DocHomePage,
  },
  {
    path: '/assthome',
    component: AsstHomePage,
  },
  {
    path: '/splash/',
    component: SplashPage,
  },
  {
    path: '/splashNew/',
    component: SplashNewPage,
  },
  {
    path: '/welcome/',
    component: WelcomePage,
  },
  {
    path: '/issuedomain',
    component: IssueDominAreaPage,
  },
  {
    path: '/issuedomain/area',
    component: IssueDominAreaFirstPage,
  },
  {
    path: '/issuedomain/area/details',
    component: IssueDominAreaSecondPage,
  },
  {
    path: '/pdsq',
    component: PdsqFirstPage,
  },
  {
    path: '/pdsq/start',
    component: PdsqSecondPage,
  },
  {
    path: '/pdsq/start/meta',
    component: PdsqThirdPage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/screens/',
    component: ScreensPage,
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/verifyotp/',
    component: VerifyOTPPage
  },
  {
    path: '/updatemobile/',
    component: UpdateMobilePage
  },
  {
    path: '/profile/',
    component: ProfilePage
  },
  {
    path: '/signUp/',
    component: SignupPage
  },
  {
    path: '/changePassword/',
    component: ChangePasswordPage
  },
  {
    path: '/forgotPassword/',
    component: ForgotPasswordPage
  },
  {
    path: '/requestsession/',
    component: RequestExpertSessionPage
  },
  {
    path: '/doctorsSearch/',
    component: DoctorsSearchPage
  },
  {
    path: '/doctorsProfile-1/:id',
    component: DoctorsProfilePage
  },
  {
    path: '/doctorsProfile/:id',
    component: DoctorsProfileNewPage
  },
  {
    path: '/contents',
    component: ContentPage
  },
  {
    path: '/contents/:area',
    component: RelativeContentPage
  },
  {
    path: '/experts/:area',
    component: RelativeExpertPage
  },
  {
    path: '/article',
    component: ArticlePage
  },
  {
    path: '/article/:id',
    component: ArticlePage
  },
  {
    path: '/article-back',
    component: ArticleBackupPage
  },
  {
    path: '/selfassessment',
    component: SelfAssessmentPage
  },
  {
    path: '/selfassessment/start/:tool',
    component: AssessStartPage
  },
  {
    path: '/selfassessment/questions/:tool',
    component: AssessQuestionPage
  },
  {
    path: '/selfassessment/questions/:tool/subques/:ques',
    component: AssessSubQuestionPage
  },
  {
    path: '/assessmentResult/',
    component: SelfAssessmentResultPage
  },
  {
    path: '/assessmentResultLavel/',
    component: SelfAssessmentResultLavelPage
  },
  {
    path: '/assessmentRecords/',
    component: AssessmentRecordsPage
  },
  {
    path: '/consultationRecords/',
    component: ConsultationRecordsPage
  },
  {
    path: '/goalSetting/',
    component: GoalSettingPage
  },
  {
    path: '/goalSettingFirst/',
    component: GoalSettingFirstPage
  },
  {
    path: '/goalSettingSecond/',
    component: GoalSettingSecondPage
  },
  {
    path: '/subscriptionPlan/',
    component: SubscriptionPlanPage
  },
  {
    path: '/subscriptionFeatures/',
    component: SubscriptionPlanFeaturesPage
  },
  {
    path: '/expertAvailability/:speciality/:docId',
    component: ExpertAvailabilityPage
  },
  {
    path: '/expertAvailabilityPayment/',
    component: ExpertAvailabilityPaymentPage
  },
  {
    path: '/expertFailedTransaction/',
    component: ExpertFailedTransactionPage
  },
  {
    path: '/paymentConfirmation/',
    component: ExpertPaymentConfirmationPage
  },
  {
    path: '/planPackage/',
    component: PlanPackagePage
  },
  {
    path: '/packageDetails/',
    component: PlanPackageDetailsPage
  },
  {
    path: '/activitiesTracking/',
    component: ActivitiesTrackingPage
  },
  {
    path: '/activitiesTrackingMood/',
    component: ActivitiesTrackingMoodPage
  },
  {
    path: '/activitiesTrackingSleep/',
    component: ActivitiesTrackingSleepPage
  },
  {
    path: '/activitiesTrackingJournal/',
    component: ActivitiesTrackingJournalPage
  },
  {
    path: '/activityPlanProgress/',
    component: ActivitiyPlanProgressPage
  },
  {
    path: '/activity/sleep',
    component: SleepTrackerPage
  },
  {
    path: '/activity/sleep/result',
    component: SleepTrackerResultPage
  },
  {
    path: '/activity/sleep/analytics',
    component: SleepTrackerAnalyticsPage
  },
  {
    path: '/activity/physic',
    component: PhysicalTrackerPage
  },
  {
    path: '/teleconsult/home',
    component: SessionPage
  }, 
  { 
    path: '/teleconsult/requestsession/:doctor',
    component: RequestSessionPage
  },
  { 
    path: '/teleconsult/requests',
    component: RequestListPage
  },  
  {
    path: '/teleconsult/request/:id/bookappt',
    component: BookSession
  },
  {
    path: '/teleconsult/session/:id/edit/:request',
    component: EditSession
  },
  {
    path: '/teleconsult/session/:id/editrequest',
    component: EditSessionRequest
  },
  {
    path: '/teleconsult/videochat/:id',
    component: VideoConsult
  },
  {
    path: '/teleconsult/textchat',
    component: TextConsult
  },
  {
    path: '/teleconsult/records',
    component: TeleRecord
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/pdsq/analytics/',
    component: PDSQAnalyticsPage,
  },
  {
    path : '/pdsq-analytics',
    component : AssessmentAnalyticsPage
  },
  {
    path : '/reschedule-appointment',
    component : RescheduleAppointmentPage
  },
  {
    path : '/success-messsage',
    component : SuccessMessagePage
  },
  
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;