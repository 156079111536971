export default {
  id: '2e69d59d64',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="page-content no-padding-bottom"><div class="main-content mobile-splash-bg-1"><div class="splash-main splash-second text-align-center"><div class=title><h2>Welcome to</h2><div class=splash-logo><img src=./static/logo_2.png class=img-fluid alt=Welcome></div></div><div class=signUp-block><h1 class=lets-start-txt>Lets Start</h1><a href=# class=signUp-btn>Sign Up</a></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};