
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import moment from 'moment';
import Highcharts from 'highcharts'; // Load the exporting module.
// import Exporting from 'highcharts/modules/exporting';
// // Initialize exporting module. (CommonJS only)
// Exporting(Highcharts);

import darkBlueTheme from 'highcharts/themes/dark-blue';
import darkGreenTheme from 'highcharts/themes/dark-green';
import darkUnicaTheme from 'highcharts/themes/dark-unica';
import gridLightThemes from 'highcharts/themes/grid-light';
import highContrastLightThemes from 'highcharts/themes/high-contrast-light';
import sandSignikaThemes from 'highcharts/themes/sand-signika';
import skiesThemes from 'highcharts/themes/skies';
import sunsetThemes from 'highcharts/themes/skies';
export default {
  data: function () {
    var data = {
      isSleepData: true
    };
    return data;
  },
  methods: {},
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$f7.dialog.preloader();
      $this.$f7.request.get($this.$app.data["endpoint"] + '/tools/activity/analytics', {
        Type: "sleep_tracker",
        StartDate: moment().startOf("month").format("DD/MM/YYYY"),
        EndDate: moment().format("DD/MM/YYYY")
      }, (data, status, xhr) => {
        $this.$f7.dialog.close();

        if (data && data["result"] && (data["result"]["Sleeps"] || []).length) {
          var avgSleep = data["result"]["AvgSleep"];
          var avgSleepData = avgSleep && avgSleep["AvgSleepHours"] ? {
            hh: moment.utc(moment.duration(avgSleep["AvgSleepHours"], "hours").asMilliseconds()).format("HH"),
            mm: moment.utc(moment.duration(avgSleep["AvgSleepHours"], "hours").asMilliseconds()).format("mm")
          } : {
            hh: 0,
            mm: 0
          },
              sleepsData = data["result"]["Sleeps"];
          $this["AvgSleepHours"] = avgSleepData;
          $this["AvgBreakTime"] = avgSleep ? Math.floor(avgSleep["AvgBreakTime"] || 0) : 0;
          $this["AvgInitiationTime"] = avgSleep ? Math.floor(avgSleep["AvgInitiationTime"] || 0) : 0;
          $this["AvgSleepEfficiency"] = avgSleep ? Math.floor(avgSleep["AvgSleepEfficiency"] || 0) : 0;
          $this["AvgSleepQuality"] = avgSleep ? avgSleep["AvgSleepQuality"] : "";
          $this["AvgTotalBreak"] = avgSleep ? Math.floor(avgSleep["AvgTotalBreak"] || 0) : 0;
          $this["AvgWakeupState"] = avgSleep ? avgSleep["AvgWakeupState"] : "";
          sleepHourChart(sleepsData, avgSleepData);
          sleepQualityChart(sleepsData, $this["AvgSleepEfficiency"]);
        } else {
          $this["isSleepData"] = false;
        }

        $this.$update();
      }, (xhr, status) => {
        $this.$f7.dialog.close();
      }, "json");

      function sleepHourChart(sleepsData, avgSleepData) {
        // darkUnicaTheme(Highcharts);
        Highcharts.chart('container', {
          chart: {
            type: 'spline'
          },
          accessibility: {
            description: ''
          },
          title: {
            text: 'Avg. Sleep: <span style="font-size: 20px">' + avgSleepData["hh"] + '<span><span style="font-size: 12px"> h </span><span style="font-size: 20px">' + avgSleepData["mm"] + '<span><span style="font-size: 12px"> m</span>',
            useHTML: true,
            margin: 15,
            style: {
              "fontSize": "16px"
            }
          },
          xAxis: {
            allowDecimals: false,
            categories: (sleepsData || []).map(function (sd) {
              return sd["Date"];
            }),
            labels: {
              formatter: function () {
                return moment(this.value, "DD MMM, YYYY").format("ddd"); // clean, unformatted number for year
              }
            }
          },
          yAxis: {
            title: {
              text: 0
            },
            labels: {
              formatter: function () {
                return this.value + " h";
              }
            },
            allowDecimals: false,
            step: 1
          },
          tooltip: {
            // pointFormat: 'Sleep hours: <b>{point.y}</b>',                        
            formatter: function () {
              return this.x + "<br/>Sleep hours: " + moment.utc(moment.duration(this.y, "hours").asMilliseconds()).format("HH:mm");
            }
          },
          credits: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            area: {
              pointStart: 0,
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 2,
                states: {
                  hover: {
                    enabled: true
                  }
                }
              }
            }
          },
          series: [{
            // name: 'Sleeping days',
            data: (sleepsData || []).map(function (sd) {
              return sd["SleepHours"];
            })
          }]
        });
      }

      ;

      function sleepQualityChart(sleepsData, avgSleepQuality) {
        // darkUnicaTheme(Highcharts);
        Highcharts.chart('container-2', {
          chart: {
            type: 'spline'
          },
          accessibility: {
            description: ''
          },
          title: {
            text: 'Avg. Sleep Quality: <span style="font-size: 20px">' + avgSleepQuality + '<span><span style="font-size: 12px"> % </span>',
            useHTML: true,
            margin: 15,
            style: {
              "fontSize": "16px"
            }
          },
          xAxis: {
            allowDecimals: false,
            categories: (sleepsData || []).map(function (sd) {
              return sd["Date"];
            }),
            labels: {
              formatter: function () {
                return moment(this.value, "DD MMM, YYYY").format("ddd"); // clean, unformatted number for year
              }
            }
          },
          yAxis: {
            title: {
              text: 0
            },
            labels: {
              formatter: function () {
                return this.value + " %";
              }
            },
            allowDecimals: false,
            step: 1
          },
          tooltip: {
            pointFormat: 'Sleep quality: <b>{point.y}</b>%' // formatter: function () {
            //     return this.x + "<br/>Sleep hours: " + moment.utc(moment.duration(this.y, "hours").asMilliseconds()).format("HH:mm");
            // }

          },
          credits: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            area: {
              pointStart: 0,
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 2,
                states: {
                  hover: {
                    enabled: true
                  }
                }
              }
            }
          },
          series: [{
            // name: 'Sleeping days',
            data: (sleepsData || []).map(function (sd) {
              return sd["SleepEfficiency"];
            })
          }]
        });
      }

      ;
    }

  },
  id: '4006cd3e21',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Sleep Analytics</div></div></div><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class=container>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isSleepData, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="expert-payment-booking main-content"><div class=row><div class="col-100 medium-50"><figure class=highcharts-figure style="margin: 0;"><div id=container style="height: 160px; padding: 5px 20px"></div></figure></div><div class="col-100 medium-50"><figure class=highcharts-figure style="margin: 0;"><div id=container-2 style="height: 160px; padding: 5px 20px"></div></figure></div></div>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.AvgSleepHours, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=expert-payment-footer><div class=payment-details-card><div class=card><div class="card-content card-content-padding"><div class=transaction-details><div class=row><div class="col-50 left"><p>Sleep Quality</p><p class=number><span class=f-16>';
              r += c(ctx_3.AvgSleepQuality, ctx_3);
              r += '</span></p></div><div class=col-50><p>Sleep</p><p class=number><span style="font-size: 16px;">';
              r += c(ctx_3.AvgSleepHours.hh, ctx_3);
              r += '</span> <span style="font-size: 12px; color: #3f3c3c;">h</span> <span style="font-size: 16px;">';
              r += c(ctx_3.AvgSleepHours.mm, ctx_3);
              r += '</span> <span style="font-size: 12px; color: #3f3c3c;">m</span></p></div></div><div class=row><div class="col-50 left"><p>Break in sleep</p><p class=number><span class=f-16>';
              r += c(ctx_3.AvgBreakTime, ctx_3);
              r += '</span> <span style="color: #3f3c3c;">m</span></p></div><div class=col-50><p>Break</p><p class=number><span class=f-16>';
              r += c(ctx_3.AvgTotalBreak, ctx_3);
              r += '</span> <span style="color: #3f3c3c;">';
              r += Template7Helpers.js_if.call(ctx_3, "this['AvgTotalBreak'] > 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'times';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += 'time';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</span></p></div></div><div class=row><div class="col-50 left"><p>Fell asleep in</p><p class=number><span class=f-16>';
              r += c(ctx_3.AvgInitiationTime, ctx_3);
              r += '</span> <span style="color: #3f3c3c;">m</span></p></div><div class=col-50><p>Wake up state</p><p class=number><span class=f-16>';
              r += c(ctx_3.AvgWakeupState, ctx_3);
              r += '</span></p></div></div></div></div></div><div class="share-receipt-block text-align-center mt-1"><a href=/contents/sleep class="button button-fill button-round share-receipt-btn">Related Resouces</a></div><div class="share-receipt-block text-align-center mt-1"><a href=/expertAvailability/MentalHealth/40cf06d5-b78b-40a8-8063-551adf6ecf5b class="button button-fill button-round share-receipt-btn">Ask Expert</a></div></div><div class=text-align-center style="visibility: hidden;"><p class=bottom-txt>Payments may take up to 3 working days to be<br>reflected in your account<br></p><p class=no-margin><img src=https://web.moxie.one/images/web_hi_res_5121.png height=50 width=50 class=img-fluid style="margin-top: 0.5rem;"></p></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<p class=text-align-center>No sleep activity record found.</p>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    