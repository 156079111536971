
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '86523815f3',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="back link"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>';
      r += c(ctx_1.user.firstName, ctx_1);
      r += ' ';
      r += c(ctx_1.user.lastName, ctx_1);
      r += '</div></div></div><div class=page-content><div class="block block-strong">';
      r += c(ctx_1.user.about, ctx_1);
      r += '</div><div class="list links-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.user.links, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a class=external target=_blank href=';
          r += c(ctx_2.url, ctx_2);
          r += '>';
          r += c(ctx_2.title, ctx_2);
          r += '</a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    