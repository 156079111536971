export default {
  id: '742873d2b5',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page welcome-page no-toolbar"><div class="page-content no-padding-bottom"><div class=pattern-bg><div class=welcome-page-inner><div class=header></div><div class="welcome-txt text-align-center"><p class="mr-0 font-alternates f-18">That\'s wonderful !!</p><h2>Let\'s explore the journey<br>to happiness</h2></div><div class="welcome-block text-align-center"><div class=welcome-img><img src=./static/welcome.png class=img-fluid alt=Welcome></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};