var utility = {
    getRequest: function ($this, route, payload, callBack, dataType) {
        $this.$f7.request.get($this.$app.data["endpoint"] + route, payload, (data, status, xhr) => {
            callBack(data, status, xhr);
        }, (xhr, status, message) => {
            callBack(null, status, xhr, message);
        }, dataType || "json");
    },
    postRequest: function ($this, route, payload, callBack, dataType) {
        $this.$f7.request.post($this.$app.data["endpoint"] + route, payload, (data, status, xhr) => {
            callBack(data, status, xhr);
        }, (xhr, status, message) => {
            callBack(null, status, xhr, message);
        }, dataType || "json");
    },
    initCalendar: function ($this, $el, callBack, options) {
        var params = {
            inputEl: $el,
            openIn: 'customModal',
            dateFormat: "dd M, yyyy",
            closeOnSelect: true,
            header: true,
            footer: true,
            backdrop: true,
            on: {
                change: callBack
            }
        };
        params = Object.assign(params, options || {});
        $this.$f7.calendar.create(params);
    },
    initSmartSelect: function ($this, $el, callBack, options) {
        $this.$f7.smartSelect.create({
            el: $el,
            pageTitle: "Select",
            closeOnSelect: true,
            cssClass: "no-toolbar",
            on: {
                opened: callBack,
                closed: callBack
            }
        });
    },
};
  
export default utility;
  