export default {
  id: '26be83d6aa',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Assessment</div></div></div><!-- Scrollable page content--><div class=page-content><div class=pattern-bg-fixed><div class=main-content><div class=container><div class="block no-margin text-align-center" style="padding: 2rem 0;"><h2>Your Symptom Profile</h2><p>The graph displays different symptoms of anxiety. We have highlighted the ones that were found to affect you most.</p><p class=mt-2><a href=# style="text-decoration: underline; color: #3a3b5b;">Learn more about your symptoms</a></p><p class=mt-3><a href=# class="button button-fill button-round button-raised theme-btn-color button-200" style="background-color: #ea9286;">Prepare My Plan</a></p></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};