
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
import utility from '../js/utility';
export default {
  data: function () {
    var area = {},
        subArea = [];
    return area;
  },
  methods: {
    onCheckClick(e) {
      var $el = $(e.currentTarget);
      if (this.subArea.includes($el.data("val"))) return false;
      this.subArea.push($el.data("val"));
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $this["area"] = page.route.context;
      $this["subArea"] = [];
      $(page.$el).find('.sub-areas .sub-area input').off("change").on('change', function (e) {
        if (e.target.checked) {
          $this.subArea.push($(e.target).data("val"));
        } else {
          $this.subArea.splice($(e.target).data("val"), 1);
        }
      });
      $(page.$el).find(".domain-save").off("click").on("click", function (e) {
        $this.$f7.dialog.preloader();
        utility.postRequest($this, "/users/emotionalprofile/" + $this.$root["user"]["Id"] + "/update", {
          ProblemArea: {
            Area: $this["area"]["Area"],
            SubArea: $this["subArea"]
          }
        }, (data, status, xhr, message) => {
          $this.$f7.dialog.close();

          if (data) {
            if (data["status"] === "success" && data["result"]) {
              $this.$root["user"]["EmotionalInfo"] = data["result"]["EmotionalInfo"];
              $this.$router.navigate("/issuedomain/area/details", {
                context: {
                  Area: $this["area"]["Area"],
                  SubArea: $this["subArea"],
                  Solution: $this["area"]["Solution"]
                }
              });
            } else {
              $this.$f7.dialog.alert("Sorry! Unable to save. Please try again.");
            }
          } else {
            $this.$f7.dialog.alert("Sorry! Unable to save. Please try again.");
          }
        });
      });
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: 'd2312bd3e4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>';
      r += c(ctx_1.Area, ctx_1);
      r += '</div></div></div><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class="issue-domain-header text-align-center"><h2 class=font-alternate>';
      r += c(ctx_1.Title, ctx_1);
      r += '</h2></div><div class=container><div class="main-content issue-domain-area-list"><div class=block><p>';
      r += c(ctx_1.Desc, ctx_1);
      r += '</p><div class="list no-hairlines mt-1"><ul class="bg-none sub-areas" style="padding-bottom: 6rem;">';
      r += Template7Helpers.each.call(ctx_1, ctx_1.SubArea, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><label class="item-checkbox item-content sub-area"><i class=margin-right><img src=./static/';
          r += c(ctx_2.Key, ctx_2);
          r += '.png alt=""></i><input type=checkbox name=demo-checkbox data-val=';
          r += c(ctx_2.Key, ctx_2);
          r += '><div class=item-inner><div class=item-title style="white-space: break-spaces;">';
          r += c(ctx_2.Desc, ctx_2);
          r += '</div></div><i class="icon icon-checkbox"></i></label></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class=issue-domain-footer style="position: fixed; bottom: 0; width: 100%; z-index: 1;"><button class="button button-fill button-round button-pink domain-save">I\'M Done</button></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    