export default {
  id: 'ab41bbc0c3',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Journal</div></div></div><div class=page-content><div class=pattern-bg-fixed><div class="activities-track-journal main-content"><div class=container><div class="row justify-content-center"><div class="col-100 large-70"><div class="block no-margin"><div class=card><div class="card-content card-content-padding"><div class=list><ul><div class="item-content item-input item-input-outline"><div class=item-inner><div class=item-input-wrap><textarea rows=4 placeholder="Describe what happened"></textarea></div></div></div></ul></div><div class="attachment-type text-align-center"><ul class="pd-0 list-inline"><li><button class=button><i class="fa fa-picture-o" aria-hidden=true></i></button></li><li><button class=button><i class="fa fa-camera" aria-hidden=true></i></button></li><li><button class=button><i class="fa fa-music" aria-hidden=true></i></button></li></ul></div></div></div></div><div class="block text-align-center select-emotion-footer"><button class="button button-fill button-round color-gray button-300">Done</button></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};