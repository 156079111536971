
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {// return this.$root["user"];
  },
  methods: {
    onLogoutClick(e) {
      var $this = this;
      $this.$root.onLogoutClick((err, isLogout) => {
        if (isLogout) {
          localStorage.removeItem("loginObj");
        } else {
          $this.$f7.dialog.alert(err.email + "[" + err.code + "]: " + err.message + " Please try again...");
        }
      });
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$update(); // $this.$f7.dialog.preloader();
    },

    pageReInit(e, page) {
      this.$update();
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '247ecaaee9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<!-- <style>\r\n      .menu-box {\r\n        position: relative;\r\n        display: flow-root;\r\n        padding: 10px;\r\n        border: 1px solid lightgray;\r\n        margin: .7rem;\r\n      }\r\n    </style> --><div class="page dashboard-page-bg" data-name=dochome><!-- Top Navbar --><div class=top-bar><div class=container><div class=navbar><!-- <div class="navbar-bg"></div> --><div class=navbar-inner><div class=main-logo><a href=# class=link><img src=../static/main-logo.png alt=Smyl style="max-width: 100%; height: auto;"></a></div><div class=right>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root["user"], {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="title sliding">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.$root["user"]["Salutation"], {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.$root["user"]["Salutation"], ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.$root["user"]["Name"], ctx_2);
          r += '</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<!-- <a href="#" class="link icon-only panel-open" data-panel="left">\r\n                  <i class="icon f7-icons if-not-md">menu</i>\r\n                  <i class="icon material-icons if-md">menu</i>\r\n                </a> --> <a class="link icon-only mobile-display-none" @click=onLogoutClick><i class="icon f7-icons if-not-md">power</i> <i class="icon material-icons if-md">power_settings_new</i></a></div><!-- <div class="title-large mt-1">\r\n                <div class="title-large-text">Smyl App</div>\r\n              </div> --></div></div></div></div><!-- Scrollable page content--><div class=page-content><!-- <div class="block">\r\n          <p>Keep smyling, Always!</p>\r\n        </div> --><div class=container><div class="home-content-block text-align-center" style="padding-bottom: 5rem;"><div class="row no-gap"><div class="col-50 medium-33 large-25"><a href=/profile/ class="menu-box profile-menu-box"><div class="dashboard-img profile-img"></div><div class=dashboard-content-title>Profile</div></a></div><div class="col-50 medium-33 large-25"><a href=/teleconsult/home class="menu-box tele-consult-box"><div class="dashboard-img ask-expert-img"></div><div class=dashboard-content-title>Tele-Consult</div></a></div><div class="col-50 medium-33 large-25"><a href=/teleconsult/requests class="menu-box ask-expert-box"><div class="dashboard-img ask-expert-img"></div><div class=dashboard-content-title>Requests</div></a></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
      .menu-box {
        position: relative;
        display: flow-root;
        padding: 10px;
        border: 1px solid lightgray;
        margin: .7rem;
      }
    `,
  styleScoped: false
};
    