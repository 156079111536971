export default {
  id: '3f64f41a61',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<!-- <style>\r\n        .assessment-content .pdsq-question-block a {\r\n            border-color: #9b8fb7;\r\n            color: #555555;\r\n            padding: 1.2rem 0rem;\r\n            line-height: 0;\r\n            width: 90%;\r\n            margin: 0 auto;\r\n            text-transform: initial;\r\n        }\r\n    </style>    --><div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>PDSQ</div></div></div><div class=page-content><div class=pattern-bg-fixed><div class=container><div class="assessment-content pdsq-health-assessment"><div class=""><div class=pdsq-top></div><div class="block no-margin"><div class=pdsq-header><div class="list no-hairlines mr-0"><ul class=bg-none><li><div class=item-content><div class=item-media><img src=./static/heart.png alt=""></div><div class=item-inner><div class=item-title><h2 class=mr-0>Health<br>Assessment</h2></div><p class="mb-2 pdsq-progressbar"><span data-progress=10 class=progressbar id=demo-inline-progressbar></span></p></div></div></li></ul></div></div><p class=pdsq-selected-que>I have a larger appetite than usual</p><p class=mb-2>How often does this happen?</p><div class="pdsq-question-block verticle-button-block" style="padding-bottom: 2rem;"><ul class=pd-0><li><a class="button button-outline">RARELY</a></li><li><a class="button button-outline">SOMETIMES</a></li><li><a class="button button-outline">OFTEN</a></li><li><a class="button button-outline">ALL THE TIME</a></li></ul></div></div><div class=pdsq-bottom style="position: fixed; width: 100%; left: 0; right: 0; bottom: 0; z-index: 1;"></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
        .assessment-content .pdsq-question-block a {
            border-color: #9b8fb7;
            color: #555555;
            padding: 1.2rem 0rem;
            line-height: 0;
            width: 90%;
            margin: 0 auto;
            text-transform: initial;
        }
    `,
  styleScoped: false
};