
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return this.$route.context;
  },
  methods: {
    onAssStartClick(toolId) {
      var $this = this;
      var assTool = $this.$route.context;
      if (!assTool) return false;
      this.$f7.views.main.router.navigate("/selfassessment/questions/" + assTool["_id"], {
        context: assTool
      });
    }

  },
  id: 'cfca9d7cd8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>';
      r += c(ctx_1.Name, ctx_1);
      r += '</div></div></div><div class=page-content><a class=pattern-bg-fixed @click="onAssStartClick(\'';
      r += c(ctx_1.$route.params.tool, ctx_1);
      r += '\')" style="color: #000000;"><div class=container><div class="main-content assessment-content"><div class=pdsq-symptom-assessment><div class=pdsq-top></div><div class="block no-margin"><div class=pdsq-symptom-content><div class="list no-hairlines mr-0"><ul class=bg-none><li><div class=item-content><div class=item-media><img src=./static/brain.png alt=""></div><div class=item-inner><div class=item-title><h2 class=mr-0>Symptom<br>Assessment</h2></div></div></div></li></ul></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$route.context, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<p>';
          r += c(ctx_2.$route.context["ShortDesc"], ctx_2);
          r += '</p>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class="pdsq-bottom text-align-center" style="position: fixed; width: 100%; bottom: 0; left: 0; right: 0;"><div class=tap-txt style="position: relative;font-size: 16px;opacity: 0.6;">**Tap anywhere to continue</div></div></div></div></div></a></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    