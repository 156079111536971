
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
export default {
  data: function () {},
  methods: {},
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$f7.dialog.preloader();
      $this.$f7.request.json($this.$app.data["endpoint"] + "/contents/" + $this.$route.params.id, {}, (data, status, xhr) => {
        $this.$f7.dialog.close();
        $this["article"] = data["result"] || null;
        $this.$update();
      }, (xhr, status) => {
        $this.$f7.dialog.close();
        $this.$update();
      });
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: 'cad9b59594',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.article, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.article.Title, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'Article';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><div class=page-content><div class=main-content><div class=container>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.article, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div style="padding: 10px;"><img src=';
          r += c(ctx_2.article.Image, ctx_2);
          r += ' class=img-fluid alt="" style="margin: 0 auto; display: table;"></div><div class=article-container><h2>';
          r += c(ctx_2.article.Title, ctx_2);
          r += '</h2><p class=mb-2>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.article.Curator, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'By ';
              r += c(ctx_3.article.Curator, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.article.CreateDate, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' | ';
              r += c(ctx_3.article.CreateDate, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p><p class=first-para>';
          r += c(ctx_2.article.FullDesc, ctx_2);
          r += '</p></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    