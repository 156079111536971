export default {
  id: '8862783709',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class="payment-transaction-failed main-content text-align-center"><div class=header></div><div class=container><div class="block no-margin"><div class=transaction-failed-content><img src=./static/transaction_failed.png class=img-fluid alt=""><p class=txt>Transaction Failed</p></div></div></div><div class=footer><div class=payment-falied-btn><button class="button button-fill button-round button-pink">Retry Payment</button></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};