
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
import moment from 'moment';
import utility from '../js/utility';
export default {
  data: function () {
    var data = {
      appointment: {}
    };
    return data;
  },
  methods: {
    goToConsultListPage(e) {
      let $this = this;
      $this.$f7.popup.close();
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      let apptData = JSON.parse(localStorage.getItem("SuccessAppointment"));
      let data1 = {
        time: moment(apptData["TimeSlot"]).format('LT'),
        amount: apptData["amount"] ? apptData["amount"] : 0.00,
        Id: apptData["Id"]
      };
      $this["appointment"] = data1;
      $this.$update();
    }

  },
  id: '030162ebf3',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class="expert-payment-booking main-content"><div class=header-profile><div class=user-payment-prfl><div class=prfl-pic><img src=../static/verified.gif height=100px width=100px class=img-fluid style="margin-top: 3px;"></div></div></div><div class=container style="position: fixed; width: inherit"><!-- <div class="payment-details-txt text-align-center">\r\n                            <h1 class="mr-0 amount"><span class="currency">&#8377;</span> 6,000</h1>\r\n                            <p class="f-14 paid-to">Paid to</p>\r\n                            <p class="name">Marti Valencia</p>\r\n                            <span class="trans-txt">Transferred to home</span>\r\n                        </div> --><div class=expert-payment-footer>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.appointment, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=payment-details-card><div class=card><div class=card-header><div class=payment-status><i class=f7-icons>checkmark_alt_circle_fill</i></div><div class=payment-detials><ul class="pd-0 mr-0"><li><span class=paid-amt>Paid &#8377; ';
          r += c(ctx_2.appointment.amount, ctx_2);
          r += '</span></li><li><span>Today, ';
          r += c(ctx_2.appointment.time, ctx_2);
          r += '</span></li></ul></div></div><div class="card-content card-content-padding"><div class=transaction-details><div class=row><div class="col-100 left"><!-- <p>UPI Transaction ID</p> --><p>Appointment ID</p><p class=number>';
          r += c(ctx_2.appointment.Id, ctx_2);
          r += '</p></div><!-- <div class="col-100 left">\r\n                                                    <p>To</p>\r\n                                                    <p class="number">****5789</p>\r\n                                                </div> --></div></div><div class="share-receipt-block text-align-center"><a class="button button-fill button-round share-receipt-btn" @click=goToConsultListPage>Consult List</a></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=text-align-center><!-- <p class="bottom-txt">\r\n                                    Payments may take up to 3 working days to be<br>\r\n                                    reflected in your account<br>                                    \r\n                                </p>                                 --><p class=no-margin><img src=../static/smyl-logo.png height=50 width=50 class=img-fluid style="margin-top: 0.5rem;"></p></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    