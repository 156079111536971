
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
export default {
  data: function () {},
  methods: {
    getContents(filter) {
      var $this = this;
      $this.$f7.dialog.preloader();
      $this.$f7.request.json($this.$app.data["endpoint"] + "/contents/", filter || {}, (data, status, xhr) => {
        $this.$f7.dialog.close();
        $this["items"] = data["result"] || [];
        $this.$update();
      }, (xhr, status) => {
        $this.$f7.dialog.close();
        $this["items"] = [];
        $this.$update();
      });
    },

    onVideoClick(e) {
      var src = $(e.currentTarget).data("src"),
          title = $(e.currentTarget).find(".title").html();
      $(this.$videoModal.$el).find(".title").html(title);
      $(this.$videoModal.$el).find("iframe").attr("src", src + "?autoplay=1");
      this.$f7.dialog.preloader();
      this.$videoModal.open();
    },

    onVideoFrameLoad(e) {
      this.$f7.dialog.close();
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      var params = $this.$route.params;
      $this.getContents({
        area: params["area"]
      });
      $this.$videoModal = $this.$f7.sheet.create({
        el: '.my-video',
        swipeToClose: true,
        swipeToStep: true,
        backdrop: true,
        on: {
          close: function (e) {
            e.$el.find("iframe").attr("src", "");
          }
        }
      });
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: 'd50b64de65',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Content</div></div></div><div class="page-content no-padding-bottom"><div class="pattern-bg-fixed main-content"><div class=container><div class=mtnl-content-inner><div id=tab-1 class="tab tab-active"><div class=text-align-left><p class=f-12 style="color: #777777; border-bottom: 1px solid #f5f5f5; padding: 0.7rem 0;">Here are the self-help content. Explore them to be happy.</p></div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.items, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="block no-padding"><h3>';
          r += c(ctx_2._id, ctx_2);
          r += '</h3><!-- Slider container --><div data-pagination=\'{"el": ".swiper-pagination"}\' data-space-between=10 data-slides-per-view=auto data-centered-slides=false class="swiper-container swiper-init demo-swiper demo-swiper-auto"><div class=swiper-wrapper>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.Contents, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.Type === 'VIDEO'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=swiper-slide data-src=';
                  r += c(ctx_4.Link, ctx_4);
                  r += ' @click=onVideoClick style="background-image: url(';
                  r += c(ctx_4.Image, ctx_4);
                  r += ');"><div class=mtl-ctn-txt><p class=title>';
                  r += c(ctx_4.Title, ctx_4);
                  r += '</p><span class="type video"><i class="fa fa-play" aria-hidden=true></i> Video <span class=video-length>';
                  r += c(ctx_4.Duration, ctx_4);
                  r += '</span></span></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.Type === 'ARTICLE'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <a class=swiper-slide href=/article/';
                  r += c(ctx_4._id, ctx_4);
                  r += ' style="background-image: url(';
                  r += c(ctx_4.Image, ctx_4);
                  r += ');"><div class=mtl-ctn-txt><p class=title>';
                  r += c(ctx_4.Title, ctx_4);
                  r += '</p><span class="type article"><i class="fa fa-file-text-o" aria-hidden=true></i> Article</span></div></a>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><!-- And somewhere in DOM --><div class="popup my-video video-popup"><div class=view><div class=page><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link popup-close"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title></div><div class=right><a class="link popup-close"><i class="fa fa-times-circle" aria-hidden=true></i></a></div></div></div><div class=page-content style="margin-top: 64px"><div class=video-container style="height: calc(100% - 64px);"><iframe class=embed-responsive-item src="" width=100% height=100% frameborder=0 allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen @load=onVideoFrameLoad></iframe></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    