
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import utility from './js/utility';
export default {
  data: function () {
    return {
      user: null
    };
  },
  methods: {
    googleLogin(cb) {
      var $this = this;

      if (window["firebase"]) {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function (result) {
          // The signed-in user info.
          return cb(null, {
            firstName: result.user.displayName,
            lastName: '',
            email: result.user.email,
            photoURL: result.user.photoURL,
            uid: result.user.uid
          });
        }).catch(function (error) {
          return cb(error);
        });
      } else {
        cb({
          email: "N/A",
          code: "404",
          message: "Google login disabled."
        });
      }
    },

    googleLogout(cb) {
      var $this = this;

      if (window["firebase"]) {
        firebase.auth().signOut().then(function () {
          return cb(null, true);
        }).catch(function (error) {
          return cb(error);
        });
      } else {
        return cb(null, true);
      }
    },

    onLogoutClick() {
      var $this = this;
      $this.$f7.dialog.confirm('Do you want to logout?', () => {
        $this.googleLogout((err, isLogout) => {
          if (isLogout) {
            localStorage.removeItem("loginObj");
            localStorage.setItem("logout", true);
            $this.$root["user"] = null;
            $this.$root.$update();
          } else {
            console.log(err.email + "[" + err.code + "]: " + err.message + " Please try again...");
          }
        });
      });
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      let docId = $this.$f7.methods.getUrlParameter("docId");
      let apptId = $this.$f7.methods.getUrlParameter("apptId");
      $this.$f7.dialog.close();

      if (localStorage.getItem("loginObj")) {
        var userObj = JSON.parse(localStorage.getItem("loginObj"));
        $this.$root["login"] = userObj;
        $this.$root["user"] = userObj;
        $this.$app.request.setup({
          headers: {
            Authorization: $this.$root["user"]["Id"]
          }
        });
      }

      if (!$this.$root["paramRouted"]) {
        if (docId) {
          setTimeout(() => {
            $this.$app.views.main.router.navigate("/expertAvailability/MentalHealth/" + docId);
          }, 300);
        } else if (apptId) {
          setTimeout(() => {
            $this.$app.views.main.router.navigate(`/teleconsult/videochat/${apptId}?from=direct`, {
              force: true
            });
          }, 300);
        }
      }

      $this.$root.$setState({
        init: true,
        paramRouted: true
      });
      $this.$root.$update();
    }

  },

  mounted() {},

  id: '470383aed9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><!-- Left panel with cover effect--> ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.user, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="panel panel-left panel-cover theme-dark panel-init"><div class="view sidebar-nav"><div class=page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Name</div></div></div><div class="page-content left-menu sidebar-content"><div class=block><ul class="pd-0 mr-0"><li><a href=#>Settings</a></li><li><a href=#>Subscription</a></li><li><a href=#>My Plan</a></li><li><a href=#>Terms</a></li><li><a href=#>Privacy</a></li><li><a href=#>About Us</a></li><li><a @click=onLogoutClick>Logout</a></li></ul></div></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<!-- Views/Tabs container --><div class="views tabs safe-areas"><!-- Tabbar for switching views-tabs --><div class="toolbar toolbar-bottom tabbar-labels"><div class=toolbar-inner>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.user, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.user["UserType"]', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.user['UserType'] === 'DOCTOR'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <a href=#view-dochome class="tab-link tab-link-active"><i class="icon f7-icons if-not-md">house_fill</i> <i class="icon material-icons if-md">home</i> <span class=tabbar-label>Home</span> </a><span class=tab-link-highlight style="width: 100%; transform: translate3d(0%, 0px, 0px);"></span> ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.user['UserType'] === 'ASSISTANT'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.js_if.call(ctx_5, "this.user['UserType'] === 'PATIENT'", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.user['UserType'] === 'ASSISTANT'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' <a href=#view-assthome class="tab-link tab-link-active"><i class="icon f7-icons if-not-md">house_fill</i> <i class="icon material-icons if-md">home</i> <span class=tabbar-label>Home</span> </a><span class=tab-link-highlight style="width: 100%; transform: translate3d(0%, 0px, 0px);"></span> ';
                      r += Template7Helpers.js_if.call(ctx_5, "this.user['UserType'] === 'PATIENT'", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ';
                      r += Template7Helpers.js_if.call(ctx_5, "this.user['UserType'] === 'PATIENT'", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' <a href=#view-home class="tab-link tab-link-active"><i class="icon f7-icons if-not-md">house_fill</i> <i class="icon material-icons if-md">home</i> <span class=tabbar-label>Home</span> </a><a href=#view-tele class=tab-link><i class="icon f7-icons if-not-md">square_list_fill</i> <i class="fa fa-calendar fa-lg" aria-hidden=true></i><!-- <i class="icon material-icons if-md">view_list</i> --> <span class=tabbar-label>Appointment</span> </a><a href=#view-content class=tab-link><!-- <i class="icon f7-icons if-not-md">square_list_fill</i>\r\n                    <i class="icon material-icons if-md">view_list</i> --> <i class="fa fa-pencil-square fa-lg" aria-hidden=true></i> <span class=tabbar-label>Contents</span> </a><span class=tab-link-highlight style="width: 33%; transform: translate3d(0%, 0px, 0px);"></span> ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=#view-home class="tab-link tab-link-active"><i class="icon f7-icons if-not-md">house_fill</i> <i class="icon material-icons if-md">home</i> <span class=tabbar-label>Home</span> </a><a href=#view-tele class=tab-link><i class="icon f7-icons if-not-md">square_list_fill</i><!-- <i class="icon material-icons if-md">view_list</i> --> <i class="fa fa-calendar fa-lg" aria-hidden=true></i> <span class=tabbar-label>Appointment</span> </a><a href=#view-content class=tab-link><i class="icon f7-icons if-not-md">square_list_fill</i><!-- <i class="icon material-icons if-md">view_list</i> --> <i class="fa fa-pencil-square fa-lg" aria-hidden=true></i> <span class=tabbar-label>Contents</span> </a><span class=tab-link-highlight style="width: 33%; transform: translate3d(0%, 0px, 0px);"></span> ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=#view-home class="tab-link tab-link-active"><i class="icon f7-icons if-not-md">house_fill</i> <i class="icon material-icons if-md">home</i> <span class=tabbar-label>Home</span> </a><a href=#view-content class=tab-link><i class="icon f7-icons if-not-md">square_list_fill</i><!-- <i class="icon material-icons if-md">view_list</i> --> <i class="fa fa-pencil-square fa-lg" aria-hidden=true></i> <span class=tabbar-label>Contents</span> </a><span class=tab-link-highlight style="width: 50%; transform: translate3d(0%, 0px, 0px);"></span> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.user, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.user["UserType"]', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.user['UserType'] === 'DOCTOR'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class --><div id=view-dochome class="view view-main view-init tab tab-active" data-url=/dochome data-stack-pages=true><!-- Home page will be loaded here dynamically from / route --></div>';
                  r += Template7Helpers.js_if.call(ctx_4, "this.user['UserType'] && this.$root.user['UserType'] === 'ASSISTANT'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.js_if.call(ctx_5, "this.user['UserType'] && this.$root.user['UserType'] === 'PATIENT'", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.user['UserType'] && this.$root.user['UserType'] === 'ASSISTANT'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class --><div id=view-assthome class="view view-main view-init tab tab-active" data-url=/assthome data-stack-pages=true><!-- Home page will be loaded here dynamically from / route --></div>';
                      r += Template7Helpers.js_if.call(ctx_5, "this.user['UserType'] && this.$root.user['UserType'] === 'PATIENT'", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ';
                      r += Template7Helpers.js_if.call(ctx_5, "this.user['UserType'] && this.$root.user['UserType'] === 'PATIENT'", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div id=view-home class="view view-main view-init tab tab-active" data-url=/home data-stack-pages=true></div><div id=view-tele class="view view-init tab" data-url=/teleconsult/home data-stack-pages=true></div><div id=view-content class="view view-init tab" data-url=/contents data-stack-pages=true></div>';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class --><div id=view-home class="view view-main view-init tab tab-active" data-url=/home data-stack-pages=true><!-- Home page will be loaded here dynamically from / route --></div><div id=view-tele class="view view-init tab" data-url=/teleconsult/home></div><!-- Settings View --><div id=view-content class="view view-init tab" data-url=/contents><!-- Settings page will be loaded here dynamically from /settings/ route --></div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class --><div id=view-home class="view view-main view-init tab tab-active" data-url=/home data-stack-pages=true><!-- Home page will be loaded here dynamically from / route --></div><!-- <div id="view-tele" class="view view-init tab" data-url="/teleconsult/home">\r\n        </div> --><!-- Settings View --><div id=view-content class="view view-init tab" data-url=/contents><!-- Settings page will be loaded here dynamically from /settings/ route --></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    