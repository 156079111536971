
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
import moment from 'moment';
import utility from '../js/utility';
export default {
  data: function () {
    var data = {
      doctor: {},
      schedule: {
        Date: "21 Jan",
        Duration: "30 mins",
        Mode: 'Video'
      },
      days: [],
      slots: [] //["09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45" , "11:00", "11:15", "11:30", "11:45" , "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45"]

    };

    for (var i = 0; i < 30; i++) {
      if (moment().add(i, "days").format("ddd") != "Sun") {
        data["days"].push({
          IsSelected: i === 0 ? true : false,
          Date: moment().add(i, "days").format("DD MMM, YYYY"),
          Day: moment().add(i, "days").format("ddd")
        });
      }
    }

    return data;
  },
  methods: {
    onDateSelect(date) {
      var $this = this;
      let validateSlot = [];
      utility.getRequest($this, "/services/expert/" + $this["doctor"]["id"] + "/slots/" + moment(date).format("DD-MM-YY"), {}, (data, status, xhr, message) => {
        $this.$f7.dialog.close();

        if (data && data["status"] === "success" && data["result"]) {
          $this["slots"] = data["result"]["Slots"];
        }

        if (moment(new Date()).format("DD-MM-YY") == moment(date).format("DD-MM-YY")) {
          for (var i = 0; i < $this["slots"].length; i++) {
            if ($this["slots"][i] > moment().format("HH:mm")) {
              validateSlot.push($this["slots"][i]);
            }
          }

          $this["slots"] = validateSlot;
        }

        $this["schedule"]["Date"] = date;
        $this.$update();
      });
    },

    onSlotSelect(slot, index) {
      var $this = this;
      $this["schedule"]["Slot"] = slot;
      $this["schedule"]["Mode"] = "Video";
      $this.$el.find(".time-block").find(".time-slot").removeClass("selected-time");
      $this.$el.find('.time-slot' + index).addClass("selected-time");
      $this.$el.find(".session-footer").show();
      $this.$el.find(".mode-session-block").removeClass("selected-session-mode");
      $this.$el.find(".mode-session-block").addClass("selected-session-mode");
      $this.$el.find(".book-app-btn").show();
      $this.$update();
    },

    onSelectMode(mode) {
      var $this = this;
      $this["schedule"]["Mode"] = mode;
      $this.$update();
    },

    onBookApptConfirmationClick(e) {
      var $this = this;
      let payload = {
        doctor: $this["doctor"],
        mode: $this["schedule"]["Mode"],
        slot: $this["schedule"]["Slot"],
        date: $this["schedule"]["Date"],
        patient: $this.$root["user"]
      };

      if ($this.$root["user"]) {
        $this.$router.navigate("/expertAvailabilityPayment/", {
          context: payload
        });
      } else {
        let newpopup = $this.$f7.popup.create({
          content: `
                        <div class="popup" id="popup">
                            <div class="view view-init" data-name="login" data-url="/login/"></div>
                        </div>
                        `.trim(),
          on: {
            closed: e => {
              localStorage.removeItem("popup-open");

              if ($this.$root["user"]) {
                payload["patient"] = $this.$root["user"];
                setTimeout(() => {
                  $this.$app.views.main.router.navigate("/expertAvailabilityPayment/", {
                    context: payload
                  });
                }, 300);
              }
            }
          }
        });
        localStorage.setItem("popup-open", true);
        newpopup.open();
      }
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $(page.$el).find(".cal-date").off("click").on("click", function (e) {
        $(this).siblings(".cal-date").removeClass("selected-date");
        $(this).addClass("selected-date");
        $(page.$el).find(".time-slot").removeClass("selected-time");
        $(page.$el).find(".session-footer").hide();
        $(page.$el).find(".book-app-btn").hide();
      });
      $(page.$el).find(".time-slot").off("click").on("click", function (e) {
        $(this).parents(".time-block").find(".time-slot").removeClass("selected-time");
        $(this).addClass("selected-time");
        $(page.$el).find(".session-footer").show();
        $(page.$el).find(".mode-session-block").removeClass("selected-session-mode");
        $(page.$el).find(".mode-session-block").addClass("selected-session-mode");
        $(page.$el).find(".book-app-btn").show();
      });
      $(page.$el).find(".mode-session-block").off("click").on("click", function (e) {
        $(page.$el).find(".mode-session-block").removeClass("selected-session-mode");
        $(this).addClass("selected-session-mode");
        $(page.$el).find(".book-app-btn").show();
      });
      utility.getRequest($this, "/services/expert/" + this.$route.params.docId, {}, (data, status, xhr) => {
        if (data) {
          $this["doctor"] = data["result"];
          let validateSlot = [];
          utility.getRequest($this, "/services/expert/" + $this["doctor"]["id"] + "/slots/" + moment(new Date()).format("DD-MM-YY"), {}, (data, status, xhr, message) => {
            $this.$f7.dialog.close();

            if (data && data["status"] === "success" && data["result"]) {
              $this["slots"] = data["result"]["Slots"];
            }

            for (var i = 0; i < $this["slots"].length; i++) {
              if ($this["slots"][i] > moment().format("HH:mm")) {
                validateSlot.push($this["slots"][i]);
              }
            }

            $this["slots"] = validateSlot;
            $this["schedule"]["Date"] = moment(new Date()).format("ll");
            $this.$update();
          });
        }
      });
    },

    pageReInit(e, page) {
      this.$update();
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '40cc7c1068',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Booking summary</div></div></div><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.doctor, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="expert-availability main-content"><div class=top-header><div class=top-card><div class=card><div class=card-content><div class=doc-small-card><div class="doc-avtar elevation-3">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.ProfilePic, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=';
              r += c(ctx_3.ProfilePic, ctx_3);
              r += ' class=img-fluid alt=""> ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=../static/DefaultProfile.png class=img-fluid alt=""> ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=doc-prfl-right><ul class="pd-0 mr-0"><li><span class=title>';
          r += c(ctx_2.doctor.Name, ctx_2);
          r += '</span></li><li>';
          r += c(ctx_2.doctor.Qualification, ctx_2);
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.Speciality, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' - ';
              r += c(ctx_3.doctor.Speciality, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.Experience, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ', ';
              r += c(ctx_3.doctor.Experience, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</li><li>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.Languages, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Speaks ';
              r += c(ctx_3.doctor.Languages, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</li></ul></div></div></div></div></div></div><div class=container><div class=booking-summary-block><h3>Booking Summary</h3><div class="block mr-0"><div class="row no-gap"><div class="col text-align-center"><p class=heading>Date</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Date, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Date, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div><div class="col text-align-center"><p class=heading>Time</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Slot, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Slot, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div><div class="col text-align-center"><p class=heading>Duration</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Duration, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Duration, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div><div class="col text-align-center"><p class=heading>Mode</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Mode, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Mode, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div></div></div></div><div class="filter-container dates-block">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.days, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.days, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <button class="cal-date ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.IsSelected, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'selected-date';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" @click="onDateSelect(\'';
                  r += c(ctx_4.Date, ctx_4);
                  r += '\')">';
                  r += c(ctx_4.Date, ctx_4);
                  r += '<br>';
                  r += c(ctx_4.Day, ctx_4);
                  r += '</button> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<!-- <button class="cal-date selected-date">30 Sep<br>Wed</button> --></div><div class=book-time-block><ul class="pd-0 mr-0 list-inline text-align-center time-block">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.slots.length, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.slots, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><button class="button button-raised time-slot time-slot';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += '" @click="onSlotSelect(\'';
                  r += c(ctx_4, ctx_4);
                  r += '\', ';
                  r += c(data_4 && data_4.index, ctx_4);
                  r += ')">';
                  r += c(ctx_4, ctx_4);
                  r += '</button></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=note-padding-css-fontstyle style="background-color: #fff !important;">No slots available Please check for other dates</li>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div><div class="block no-margin no-padding"><div class=session-footer style="position: fixed; bottom: 0; width: 100%; left: 0; right: 0; display: none;"><div class=mode-block><div class=row><div class=col><!--  selected-session-mode --><div class="mode-session-block text-align-center" data-mode=VIDEO @click="onSelectMode(\'Video\')"><a><div class=icon><i class="icon f7-icons">videocam_fill</i></div></a></div></div></div></div><!-- <a class="button button-raised button-round w-100 btn-gredi-color" @click="onBookApptClick(\'';
          r += c(ctx_2.id, ctx_2);
          r += '\')">Book Consulation</a> --> <a class="button button-round book-app-btn" style="display: none;" @click=onBookApptConfirmationClick>Proceed</a><!-- <button class="button button-small button-100 button-raised button-fill button-round theme-btn-color">Proceed</button> --></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    