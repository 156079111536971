import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';
// import Swiper styles
import 'swiper/swiper-bundle.css';
// Intl-tel-input 
import 'intl-tel-input/build/css/intlTelInput.css';
// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import '../css/style.css';
import '../css/custom-style.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';
// Import Cordova APIs
// import firebaseApp from './init-firebase.js';

// Import main app component
import App from '../app.f7.html';
var colours = ["#1abc9c", "#2ecc71", "#fba657", "#9b59b6",
    "#34495e", "#16a085", "#27ae60", "#4caf50", "#8e44ad", "#2c3e50",
    "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400",
    "#c0392b", "#bdc3c7", "#7f8c8d"
];

var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component
  id: 'one.moxie.emotional', // App bundle ID
  name: 'Moxie Emotional', // App name
  theme: 'auto', // Automatic theme detection
  // App routes
  routes: routes,
  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
      }
    },
  },
  data: function () {
    return { 
      endpoint: (process.env.TARGET === 'dev' ? "http://localhost:1338" : "https://server.smyl.one"),
      user: this.data.user 
    };
  },
  methods: {
    userData() {
      return this.data.user;
    },
    getUrlParameter(sParam) {
      var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    
      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
    
        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
      }
  },

    getGuestUserData() {
        let userData = {
        "_id" : "63e0ca4ebe8ff9eb281007cb",
        "Languages" : [
    
        ],
        "Setting" : [
    
        ],
        "Status" : "ACTIVE",
        "Id" : "36672795-d8da-49f3-a4cb-acc70ea3f5eg",
        "PhoneNo" : "1111111111",
        "Country" : {
            "name" : "India (भारत)",
            "iso2" : "in",
            "dialCode" : "91",
            "priority" : "0",
            "areaCodes" : "",
            "iso" : "IN",
            "currency" : "INR",
            "symbol" : "₹",
            "countryName" : "India"
        },
        "Address" : {
            "Line1" : "",
            "Line2" : "",
            "City" : "",
            "State" : "",
            "Country" : "India (भारत)",
            "Location" : [
    
            ]
        },
        "AsstSetting" : [
    
        ],
        "ProblemDomain" : [
    
        ],
        "Devices" : [
    
        ],
        "updatedAt" : "2022-11-21T10:30:26.261+0000",
        "createdAt" : "2021-09-24T10:56:01.920+0000",
        // "__v" : NumberInt(0),
        "Age18" : true,
        "DOB" : "1992-06-30T18:30:00.000+0000",
        "Email" : "",
        "Gender" : "Male",
        "Name" : "Guest",
        "TC" : true,
        "EmotionalInfo" : {
            "ProblemArea" : [
                {
                    "Area" : "Sleep",
                    "SubArea" : [
                        "relationship"
                    ]
                }
            ]
        },
        "DialCode" : "91",
        "GovId" : {
            "Type" : "HealthId",
            "Id" : "123456789"
        },
        "Salutation" : "Mr.",
        "Password" : "guestuser",
        "UserName" : "guestuser"
      }
      return userData;
    },
    
    getFullName(profileObj, isDoc) {
      if (!profileObj) return "";
      var prefix = "";
      if (isDoc) {
        prefix = "Dr. "
      } else if (profileObj.Salutation && profileObj.Salutation !== "") {
        prefix = profileObj.Salutation + " ";
      }
      var fullName = profileObj.FirstName;
      fullName += profileObj.MiddleName != "" ? " " + profileObj.MiddleName : "";
      fullName += profileObj.LastName != "" ? " " + profileObj.LastName : "";
      return prefix + fullName;
    },
    getShortName(profileObj, isDoc) {
      if (!profileObj) return "";
      var prefix = "";
      if (isDoc) {
        prefix = "Dr. "
      } else if (profileObj.Salutation && profileObj.Salutation !== "") {
        prefix = profileObj.Salutation + " ";
      }
      var fullName = profileObj.FirstName;
      fullName += profileObj.LastName != "" ? " " + profileObj.LastName : "";
      return prefix + fullName;
    },
    editFullName(profileObj) {
      if (!profileObj) return "";
      var fullName = profileObj.FirstName;
      fullName += profileObj.MiddleName != "" ? " " + profileObj.MiddleName : "";
      fullName += profileObj.LastName != "" ? " " + profileObj.LastName : "";
      return fullName;
    },
    splitName(fullName) {
      fullName = fullName.replace(/\s+/g, ' ').replace(/dr\.\s/gi, "").replace(/dr\s/gi, "");
      var names = fullName.split(' ');

      var firstName = names[0];
      var middleName = names.slice(1, 1 + names.length - 2).join(' ');
      var lastName = names.length > 1 ? names[names.length - 1] : '';
      return { Name: fullName, FirstName: firstName, MiddleName: middleName, LastName: lastName };
    },
    concatName(profileJson) {
      if (profileJson) {
        var mdlName = "";
        if (profileJson.MiddleName && profileJson.MiddleName.trim() != "") {
          mdlName = " " + profileJson.MiddleName;
        }
        return profileJson.FirstName + mdlName + " " + profileJson.LastName;
      } else {
        return "";
      }
    },
    getRelation(gRel, gName, pGender) {
      var rel = gRel ? gRel.toLowerCase() : "";
      pGender = pGender ? pGender.toLowerCase() : "";
      if (rel === "father" || rel === "mother") {
        return { GRel: gRel, PRel: "Child" };
      } else if (rel === "spouse" || rel === "sibling") {
        return { GRel: gRel, PRel: gRel };
      } else if (rel === "child") {
        if (pGender === "male") return { GRel: gRel, PRel: "Father" };
        if (pGender === "female") return { GRel: gRel, PRel: "Mother" };
      } else if (rel === "other") {
        return { GRel: gRel, PRel: "Other" };
      } else if (rel === "self") {
        if (!gName || gName === "") {
          return { GRel: null, PRel: "Self" };
        } else {
          return { GRel: "Other", PRel: "Other" };
        }
      } else if (rel === "-1") {
        if (!gName || gName === "") {
          return { GRel: null, PRel: "Other" };
        } else {
          return { GRel: "Other", PRel: "Other" };
        }
      } else {
        return { GRel: null, PRel: "Other" };
      }
    },
    dispGuardian(gRel, pGender) {
      var rel = gRel ? gRel.toLowerCase() : "";
      pGender = pGender ? pGender.toLowerCase() : "";
      if (rel === "father" || rel === "mother") {
        if (pGender === "male") return "S/O";
        if (pGender === "female") return "D/O";
      } else if (rel === "spouse") {
        if (pGender === "male") return "H/O";
        if (pGender === "female") return "W/O";
      } else if (rel === "sibling") {
        if (pGender === "male") return "Bro/O";
        if (pGender === "female") return "Sis/O";
      } else if (rel === "child") {
        if (pGender === "male") return "F/O";
        if (pGender === "female") return "M/O";
      } else if (rel === "other") {
        return "C/O";
      } else {
        return "C/O";
      }
    },
    getPhoneNo(item) {
      var phoneNo = "";
      if (item && item.PhoneNo && item.PhoneNo !== "") {
        var cCode = item.DialCode && item.DialCode != "" ? item.DialCode + " " : "";
        phoneNo = (item.PhoneNo && item.PhoneNo !== "" ? cCode + item.PhoneNo : "");
      } else {
        phoneNo: "N/A";
      }
      return phoneNo;
    },
    getDocSpeciality(item, joinWith) {
      var spec = item.map(function (itm) {
        return itm.Type;
      });
      return spec.join(joinWith || ' / ');
    },
    getDocQualification(item, joinWith) {
      var qual = item.map(function (itm) {
        return itm["Name"] || "";
      });
      return qual.join(joinWith || ' / ');
    },
    getProfilePic(profileJson, purpose, userType) {
      if (purpose === "PROFILE") {
        if (profileJson.ProfileImg && profileJson.ProfileImg.Thumbnail) {
          return (serverName || servername) + "/files/download/profile/" + profileJson.ProfileImg.Thumbnail;
        } else if (profileJson && profileJson.ProfilePic && profileJson.ProfilePic.indexOf("thumb") > -1) {
          return profileJson.ProfilePic;
        } else if (profileJson && profileJson.ProfilePic && profileJson.ProfilePic != "") {
          var a = profileJson.ProfilePic.split("/");
          var b = a[profileJson.ProfilePic.split("/").length - 1];
          a.splice(profileJson.ProfilePic.split("/").length - 1, 1);
          a = a.join("/");
          a = a + "/thumb_" + b
          return a;
        } else if (profileJson && profileJson.ThumbProfilePic && profileJson.ThumbProfilePic != "") {
          //is url contain server route or not
          if (profileJson.ThumbProfilePic.split('/').length > 1) {
            return profileJson.ThumbProfilePic;
          } else {
            //add server route
            var rt = generateProfileRoute(userType) + profileJson.ThumbProfilePic;
            return rt;
          }
        } else {
          return this.methods.createAvatar(profileJson.FirstName || (profileJson.Name || ""));
        }
      } else {
        return profileJson.ProfilePic;
      }
    },
    generateProfileRoute(userType) {
      if (userType === "PATIENT") {
        return serverName + "/pntgetprofilepic/";
      } else if (userType === "DOCTOR") {
        return serverName + "/docgetprofilepic/";
      } else if (userType === "ASSISTANT") {
        return serverName + "/pntgetprofilepic/";
      }
    },
    getCity(address) {
      if (!address) return null;
      return (address.City && address.City != "" ? address.City : null);
    },
    getAddress(address, isCity, isPin, isState, isCountry) {
      if (!address) return "";
      var add = "";
      add += address.Line1 && address.Line1 != "" ? address.Line1 : "";
      if (isCity) add += address.City && address.City != "" ? (add != "" ? ", " : "") + address.City : "";
      if (isState) add += address.State && address.State != "" ? (add != "" ? ", " : "") + address.State : "";
      if (isCountry) add += address.Country && address.Country != "" ? (add != "" ? ", " : "") + address.Country : "";
      if (isPin) add += address.PIN && address.PIN != "" ? (add != "" ? ", " : "") + address.PIN : "";
      return add;
    },
    getFullAddress(address, noPIN) {
      if (!address) return "";
      var add = "";
      add += address.Line1 && address.Line1 != "" ? address.Line1 : "";
      add += address.Line2 && address.Line2 != "" ? (add != "" ? ", " : "") + address.Line2 : "";
      add += address.City && address.City != "" ? (add != "" ? ", " : "") + address.City : "";
      add += address.State && address.State != "" ? (add != "" ? ", " : "") + address.State : "";
      if (!noPIN)
        add += address.PIN && address.PIN != "" ? (add != "" ? ", " : "") + address.PIN : "";
      return add;
    },
    getPipeAddress(address, noPIN) {
      if (!address) return "";
      var add = "";
      add += address.Line1 && address.Line1 != "" ? address.Line1 : "";
      add += address.Line2 && address.Line2 != "" ? (add != "" ? " | " : "") + address.Line2 : "";
      add += address.City && address.City != "" ? (add != "" ? " | " : "") + address.City : "";
      add += address.State && address.State != "" ? (add != "" ? " | " : "") + address.State : "";
      if (!noPIN)
        add += address.PIN && address.PIN != "" ? (add != "" ? " - " : "") + address.PIN : "";
      return add;
    },
    createAvatar(name) {
      var nameSplit = name.split(" "),
        initials = nameSplit[0].charAt(0).toUpperCase();
      // if (nameSplit.length > 1) {
      //     initials += nameSplit[nameSplit.length - 1].charAt(0).toUpperCase();
      // }

      var charIndex = initials.charCodeAt(0) - 65,
        colourIndex = Math.floor((Math.random() * 19) + 1) - 1;

      var canvas = document.createElement('canvas');
      canvas.width = 250;
      canvas.height = 250;
      var context = canvas.getContext("2d");

      var canvasWidth = $$(canvas).attr("width"),
        canvasHeight = $$(canvas).attr("height"),
        canvasCssWidth = canvasWidth,
        canvasCssHeight = canvasHeight;

      if (window.devicePixelRatio) {
        $$(canvas).attr("width", canvasWidth * window.devicePixelRatio);
        $$(canvas).attr("height", canvasHeight * window.devicePixelRatio);
        $$(canvas).css("width", canvasCssWidth);
        $$(canvas).css("height", canvasCssHeight);
        context.scale(window.devicePixelRatio, window.devicePixelRatio);
      }

      context.fillStyle = colours[colourIndex];
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.font = "120px Arial";
      context.textAlign = "center";
      context.fillStyle = "#FFF";
      context.fillText(initials, canvasCssWidth / 2, canvasCssHeight / 1.5);
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      return image.src;
    },
    getrandColor () {

    },
  },
});

