
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import Swiper, { Navigation, Pagination } from 'swiper';
export default {
  data: function () {
    var tool = {},
        questions = [],
        answers = [];
    return tool;
  },
  methods: {
    onAnswerClick(e) {
      var $this = this;
      var data = e.target.dataset;
      var answer = ($this["answers"] || []).find((val, index) => {
        return val["SrNo"] === data["srno"];
      });
      if (!answer) $this.answers.push(data);

      if ($this.qSwiper.isEnd) {
        var score = 0;

        for (var i = 0; i < $this.answers.length; i++) {
          score += parseInt($this.answers[i]["score"]);
        }

        var recommendation = ($this["Tool"]["Recommendations"] || []).find((val, index) => {
          return val["MinScore"] <= score && val["MaxScore"] >= score;
        }) || {};
        recommendation["Score"] = score;
        recommendation["ProblemDomain"] = $this["Tool"]["ProblemDomain"][0];

        if ($this.$root.user) {
          $this.$f7.dialog.preloader();
          let payload = {
            Result: $this.answers,
            Score: recommendation["Score"],
            Patient: $this.$root.user["Id"] || "",
            ToolName: $this["Tool"]["Name"],
            ToolId: $this["Tool"]["_id"],
            ToolDetails: $this["Tool"],
            Recommendation: recommendation
          };
          $this.$f7.request.post($this.$app.data["endpoint"] + '/services/savePDSQResult/', {
            Payload: payload
          }, (data, status, xhr) => {
            $this.$f7.dialog.close();
            data = JSON.parse(data);

            if (data && data["result"] === "SUCCESS" && data["Data"]) {
              $this.$router.navigate("/assessmentResultLavel/", {
                context: recommendation
              });
            }
          });
        } else {
          $this.$router.navigate("/assessmentResultLavel/", {
            context: recommendation
          });
        } // 

      } else {
        $this.qSwiper.slideNext();
      }
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.answers = [];
      Swiper.use([Navigation, Pagination]);
      $this.$f7.dialog.preloader();
      $this.$f7.request.get($this.$app.data["endpoint"] + '/services/tool/' + $this.$route.context["_id"], {}, (data, status, xhr) => {
        $this.$f7.dialog.close();
        $this["Tool"] = data["result"] ? data["result"] || {} : {};
        $this.$update();
        setTimeout(function () {
          new Swiper('.swiper-container', {
            pagination: {
              el: ".swiper-pagination",
              type: 'progressbar'
            },
            spaceBetween: 15,
            allowTouchMove: false
          });
          $this.qSwiper = $this.$app.swiper.get(".swiper-container");
        }, 300);
      }, (xhr, status) => {
        $this.$f7.dialog.close();
        $this["Tool"] = {};
        $this.$update();
      }, "json"); // $this.$f7.dialog.preloader();
      // $this["userobj"] = page.route.context;
      // $.validator.addMethod("regex", function (value, element, regexpr) {
      //   return regexpr.test(value);
      // }, "Incorrect mobile number.");
      // $(".verify-otp-form").validate({
      //   rules: {
      //     Otp: {
      //       required: true,
      //       minlength: 4,
      //       maxlength: 4,
      //     }
      //   },
      //   messages: {
      //     Otp: {
      //       required: "Please enter otp number.",
      //       minlength: "Incorrect otp number.",
      //       maxlength: "Incorrect otp number."
      //     }
      //   }, errorPlacement: function (error, element) {
      //     if (element.attr("name") == "Otp")  //Id of input field
      //       error.appendTo('.otp-error');
      //   },
      //   submitHandler: function (form) {
      //     return false;
      //   }
      // });
      // page.$el.find(".otp-box").focus();
      // $this.$update();
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '65df29ce3c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<!-- <style>\r\n          .assessment-content .pdsq-question-block a {\r\n              border-color: #9b8fb7;\r\n              color: #555555;\r\n              padding: 1.2rem 0rem;\r\n              line-height: 0;\r\n              width: 90%;\r\n              margin: 0 auto;\r\n              text-transform: initial;\r\n          }\r\n      </style> --><div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>';
      r += c(ctx_1.$route.context["Name"], ctx_1);
      r += '</div></div></div><div class=page-content><div class="pattern-bg-fixed main-content"><div class=container><div class=assessment-content><!-- <div class="pdsq-top"></div> --><div class="block no-margin"><div class=pdsq-health-assessment><div class=pdsq-header><div class="list no-hairlines mr-0"><ul class=bg-none><li><div class=item-content><div class=item-media><img src=./static/heart.png alt=""></div><div class=item-inner><div class=item-title><h2 class=mr-0>Health<br>Assessment</h2></div></div></div></li></ul></div></div><!-- Slider container --><div class=swiper-container><div class=swiper-pagination></div><div class=swiper-wrapper style="margin-top: 1rem;">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.Tool, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.Tool["Questions"], {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="swiper-slide pdq-ques-block">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.Title, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<p class=pdsq-selected-que>';
                  r += c(ctx_4.Title, ctx_4);
                  r += '</p>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<div class="dsq-question-block verticle-button-block" style="padding-bottom: 3rem;">';
              r += Template7Helpers.if.call(ctx_3, ctx_3.Text, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<p class="mt-1 mb-1 ques-area" data-srno=';
                  r += c(ctx_4.SrNo, ctx_4);
                  r += ' data-question="\'';
                  r += c(ctx_4.Text, ctx_4);
                  r += '\'"><b>';
                  r += c(ctx_4.SrNo, ctx_4);
                  r += '. </b>';
                  r += c(ctx_4.Text, ctx_4);
                  r += '</p>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<ul class=pd-0>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.AnswerChoice, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li class=pdsq-question-block><button class="button button-outline ans-opt" data-section="\'';
                  r += c(ctx_3.Title, ctx_4);
                  r += '\'" data-srno=';
                  r += c(ctx_3.SrNo, ctx_4);
                  r += ' data-question="\'';
                  r += c(ctx_3.Text, ctx_4);
                  r += '\'" data-score=';
                  r += c(ctx_4.Score, ctx_4);
                  r += ' data-answer="\'';
                  r += c(ctx_4.Answer, ctx_4);
                  r += '\'" @click=onAnswerClick>';
                  r += c(ctx_4.Answer, ctx_4);
                  r += '</button></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div><!-- <div class="pdsq-bottom" style="position: fixed; width: 100%; bottom: 0; left: 0; right: 0; z-index: 1;"></div> --></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
          .assessment-content .pdsq-question-block a {
              border-color: #9b8fb7;
              color: #555555;
              padding: 1.2rem 0rem;
              line-height: 0;
              width: 90%;
              margin: 0 auto;
              text-transform: initial;
          }
      `,
  styleScoped: false
};
    