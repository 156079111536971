
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
import moment from 'moment';
import utility from '../js/utility';
export default {
  data: function () {
    var allowInfinite = true;
    var hasMoreItems = false;
    var skip = 0,
        limit = 20;
    var items = [];
    return {
      doctor: (this.$root.user || {})["UserType"] === "DOCTOR" || (this.$root.user || {})["UserType"] === "ASSISTANT" ? true : false,
      assistant: (this.$root.user || {})["UserType"] === "ASSISTANT" ? true : false,
      allowInfinite: allowInfinite,
      hasMoreItems: hasMoreItems,
      skip: skip,
      limit: limit,
      items: items,
      noRequest: null
    };
  },
  methods: {
    onRequestSessionClick(reqId) {
      var request = this.items.find(item => {
        return item["id"] === reqId;
      });

      if (request) {
        if (request["Status"] === "ACTIVE" && request["SessionDetails"]) this.$router.navigate("/teleconsult/session/" + request["SessionDetails"]["id"] + "/edit/" + reqId);else if (request["Status"] === "ACTIVE") this.$router.navigate("/teleconsult/request/" + reqId + "/bookappt");else if (request["Status"] === "COMPLETED") this.$f7.toast.show({
          text: "This request is resolved.",
          closeTimeout: 2000,
          destroyOnClose: true
        });else this.$f7.toast.show({
          text: "This request is deleted.",
          closeTimeout: 2000,
          destroyOnClose: true
        });
      } else if (reqId === "NEW") {
        this.$router.navigate("/teleconsult/request/" + 'New' + "/bookappt");
      } else {
        console.log("Request not found.");
      }
    },

    loadMore() {
      var $this = this;
      if (!$this.allowInfinite) return;
      $this.allowInfinite = false; // setTimeout(function () {
      //     if ($this.lastItem >= 200) {
      //         $this.hasMoreItems = false;
      //         $this.$update();
      //         return;
      //     }
      //     for (var i = 1; i <= 20; i++) {
      //         $this.items.push($this.lastItem + i);
      //     }
      //     $this.allowInfinite = true;
      //     $this.lastItem += 20;
      //     $this.$update();
      // }, 1000);
    },

    onPageLoad($this) {
      $this.$f7.dialog.preloader();
      utility.getRequest($this, "/consult/requests/null", {}, (data, status, xhr, message) => {
        $this.$f7.dialog.close();

        if (data && data["status"] === "success") {
          $this["items"] = data["result"] || [];
          $this["noRequest"] = !$this["items"].length ? true : null;
        } else {
          $this.$f7.dialog.alert("Failed! No request found.");
        }

        $this.$update();
      });
    }

  },
  on: {
    pageInit(e, page) {
      this.onPageLoad(this);
    },

    pageReInit(e, page) {
      this.onPageLoad(this);
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: 'f5d22a826e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class=navbar-inner><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Session Requests</div></div></div><div class="page-content infinite-scroll-content" @infinite=loadMore><div class=pattern-bg-fixed><div class=container><div class="content-block session-request-inner"><div class="list no-margin media-list mt-1">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.noRequest, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<p class="mt-2 text-align-center">No request record found.</p>';
          r += Template7Helpers.if.call(ctx_2, ctx_1.doctor, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_1.doctor, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_1.doctor, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.SessionDetails, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_1.assistant, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=bg-none>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.items, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=card>';
              r += Template7Helpers.js_if.call(ctx_3, "this.Status === 'ACTIVE'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.if.call(ctx_4, ctx_2.doctor, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div @click="onRequestSessionClick(\'';
                      r += c(ctx_5.id, ctx_5);
                      r += '\')">';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div>';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.Status === 'COMPLETED'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.Status === 'COMPLETED'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div @click="onRequestSessionClick(\'';
                      r += c(ctx_5.id, ctx_5);
                      r += '\')">';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div @click="onRequestSessionClick(\'';
                      r += c(ctx_5.id, ctx_5);
                      r += '\')">';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<div class=item-content><div class=item-media style="display: block; text-align: -webkit-center;"><div class=row><div class="col-100 center-align"><div class="doc-det-left elevation-3 sz-35">';
              r += Template7Helpers.if.call(ctx_3, ctx_2.doctor, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="cu-avatar sz-35">';
                  r += c(ctx_4.PatientDetails.FirstChar, ctx_4);
                  r += '</div>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="cu-avatar sz-35">';
                  r += c(ctx_4.DoctorDetails.FirstChar, ctx_4);
                  r += '</div>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></div><div class=row><div class="col-100 center-align pt-0" style="font-size: 10px; padding-top: 5px">';
              r += Template7Helpers.js_if.call(ctx_3, "this.Status === 'ACTIVE'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <span class="f-capital text-color-orange">Pending</span> ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.Status === 'COMPLETED'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.Status === 'COMPLETED'", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' <span class="f-capital text-color-green">Resolved</span> ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' <span class="f-capital text-color-red">Deleted</span> ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></div><div class=item-inner><div class=item-title-row><div class=item-title>';
              r += Template7Helpers.if.call(ctx_3, ctx_2.doctor, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += c(ctx_4.PatientDetails.Salutation, ctx_4);
                  r += ' ';
                  r += c(ctx_4.PatientDetails.Name, ctx_4);
                  r += ' ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += c(ctx_4.DoctorDetails.Salutation, ctx_4);
                  r += ' ';
                  r += c(ctx_4.DoctorDetails.Name, ctx_4);
                  r += ' ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-after><div class="chip bg-pink" style="height: 22px;"><div class="chip-label type-lbl">';
              r += c(ctx_3.Type, ctx_3);
              r += '</div></div></div></div><div class=item-title-row><div class=item-subtitle><div class=chip style="height: 22px; margin-right: 5px;"><div class=chip-label>';
              r += c(ctx_3.RequestedDate, ctx_3);
              r += '</div></div><div class=chip style="height: 22px;"><div class=chip-label>';
              r += c(ctx_3.Schedule, ctx_3);
              r += '</div></div></div>';
              r += Template7Helpers.if.call(ctx_3, ctx_3.SessionDetails, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=item-after><a class="link popover-open" href=# data-popover=.popover-session-info><i class="icon f7-icons">calendar_circle</i></a><div class="popover popover-session-info"><div class=popover-inner><div class=block><p class=text-align-center>Session Info</p><div class=text-align-center>----------------------</div><p>Date: <b>';
                  r += c(ctx_4.SessionDetails.Date, ctx_4);
                  r += '</b></p><p>Time: <b>';
                  r += c(ctx_4.SessionDetails.Time, ctx_4);
                  r += '</b></p><p></p><div class="chip color-green" style="height: 22px;"><div class="chip-label status-lbl">';
                  r += c(ctx_4.SessionDetails.Status, ctx_4);
                  r += '</div></div><p></p></div></div></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>';
              r += Template7Helpers.if.call(ctx_3, ctx_2.assistant, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=item-title-row><div class="item-title text-color-green">';
                  r += c(ctx_4.DoctorDetails.Salutation, ctx_4);
                  r += ' ';
                  r += c(ctx_4.DoctorDetails.Name, ctx_4);
                  r += '</div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<div class=item-title-row><div class=item-subtitle>';
              r += c(ctx_3.Note, ctx_3);
              r += '</div></div></div></div></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.hasMoreItems, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="preloader infinite-scroll-preloader"></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div><!-- ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.doctor, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' --><!-- Additional fab-extended class --><!-- <div class="toolbar toolbar-bottom tabbar-labels">\r\n            <div class="toolbar-inner" style="display: block;">\r\n                <a class="link update-btn" href="/requestsession/">\r\n                    New Request\r\n                </a>\r\n            </div>\r\n        </div> --><!-- ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' --><div class="fab fab-extended fab-right-bottom" @click="onRequestSessionClick(\'NEW\')"><a><i class="icon f7-icons">plus</i></a></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    