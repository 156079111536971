export default {
  id: 'd7ed7457af',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>PDSQ</div></div></div><div class=page-content><a class=pattern-bg-fixed href=/pdsq/start><div class=container><div class="main-content assessment-content"><div class=pdsq-symptom-assessment><div class=pdsq-top></div><div class="block no-margin"><div class=pdsq-symptom-content><div class="list no-hairlines mr-0"><ul class=bg-none><li><div class=item-content><div class=item-media><img src=./static/brain.png alt=""></div><div class=item-inner><div class=item-title><h2 class=mr-0 style="color: #000000; font-weight: 600;">Symptom<br>Assessment</h2></div></div></div></li></ul></div><p>We\'ll show you a few statements to evaluate your current concerns. Think of the last month, and select the most suitable response for each.</p></div></div><div class="pdsq-bottom text-align-center" style="position: fixed; width: 100%; left: 0; right: 0; z-index: 1; bottom: 0"><div class=tap-txt style="position: relative;font-size: 16px;opacity: 0.6;">**Tap anywhere to continue</div></div></div></div></div></a></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};