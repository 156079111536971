
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
import moment from 'moment';
import utility from '../js/utility';
export default {
  data: function () {
    var data = {
      session: null,
      dispDate: "",
      payload: {
        date: null,
        schedule: null
      }
    };
    return data;
  },
  methods: {
    onInput(e) {
      this["payload"]["note"] = $(e.target).val();
    },

    onScheduleSelect(e) {
      var $el = $$(e.currentTarget);
      this.$el.find(".time-block .time-slot").removeClass("selected-time");
      $el.addClass("selected-time");
      this["payload"]["type"] = $el.data("schedule");

      if (this["payload"]["type"] === "RESCHEDULE") {
        this.$router.navigate("/reschedule-appointment", {
          context: {
            Payload: this["session"]
          }
        }); // this.$el.find(".book-time-block").removeClass("hide");
        // this["dispDate"] = null;
        // this["payload"]["date"] = null;
        // this["payload"]["schedule"] = null;
      } else {
        this.$el.find(".book-time-block").addClass("hide");
        this["dispDate"] = null;
        this["payload"]["date"] = null;
        this["payload"]["schedule"] = null;
      }

      this.$update();
    },

    onRequestSessionClick(e) {
      var $this = this;
      $this.$f7.dialog.preloader();
      utility.postRequest($this, "/consult/session/edit/request", $this["payload"], (data, status, xhr, message) => {
        $this.$f7.dialog.close();

        if (data && data["status"] === "success") {
          $this.$f7.dialog.alert("Success! Request has been submitted.", "Session Requested", function () {
            $this.$router.back("/teleconsult/requests", {
              ignoreCache: true,
              force: true
            });
          });
        } else {
          $this.$f7.dialog.alert("Failed! Unable to submit request.");
        }

        $this.$update();
      });
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      getSession(() => {
        utility.initCalendar($this, page.$el.find('.select-date'), function (cal) {
          if ((cal["value"] || []).length) {
            $this["dispDate"] = moment(cal.value[0]).format("ddd, DD MMM, YY hh:mm A");
            $this["payload"]["date"] = moment(cal.value[0]).startOf("day").format();
            $this["payload"]["schedule"] = moment(cal.value[0]).format("HH:mm");
            $this.$update();
          }
        }, {
          timePicker: true,
          timePickerPlaceholder: "Select time",
          closeOnSelect: false
        });
      }); // $(page.$el).find(".time-slot").off("click").on("click", function (e) {
      //     $(this).parents(".time-block").find(".time-slot").removeClass("selected-time");
      //     $(this).addClass("selected-time");
      //     $(page.$el).find(".session-footer").show();
      // });

      function getSession(callBack) {
        $this.$f7.dialog.preloader();
        utility.getRequest($this, "/consult/session/" + $this.$route.params["id"], {}, (data, status, xhr, message) => {
          $this.$f7.dialog.close();

          if (data && data["status"] === "success" && data["result"]) {
            $this["session"] = data["result"];
            $this["payload"]["session"] = data["result"]["id"];
            $this.$update();
          }

          setTimeout(() => {
            callBack();
          }, 100);
        });
      }

      ;
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '7d0208d4e4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Session change request</div></div></div><!-- Scrollable page content--><div class="page-content no-padding-bottom expert-availability"><div class=pattern-bg-fixed>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.session, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=top-header><div class=top-card><div class=card><div class=card-content><div class=doc-small-card><div class="doc-avtar elevation-3">';
          r += Template7Helpers.js_if.call(ctx_2, "this.session && this.session['DoctorDetails'] && this.session['DoctorDetails']['ProfilePic']", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=';
              r += c(ctx_3.session.DoctorDetails.ProfilePic, ctx_3);
              r += ' class=img-fluid alt=""> ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="cu-avatar sz-60">';
              r += c(ctx_3.session['DoctorDetails']['FirstChar'], ctx_3);
              r += '</div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=doc-prfl-right><ul class="pd-0 mr-0"><li><span class=title>';
          r += c(ctx_2.session.DoctorDetails.Salutation, ctx_2);
          r += ' ';
          r += c(ctx_2.session.DoctorDetails.Name, ctx_2);
          r += '</span></li>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.session.DoctorDetails.Speciality, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li>';
              r += c(ctx_3.session.DoctorDetails.Speciality, ctx_3);
              r += '</li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<li><div class=chip style="height: 22px; margin-right: 5px;"><div class=chip-label>';
          r += c(ctx_2.session.SessionDate, ctx_2);
          r += ' ';
          r += c(ctx_2.session.SessionTime, ctx_2);
          r += '</div></div></li><li><div class="chip color-green" style="height: 22px; margin-right: 5px;"><div class=chip-label>Pending</div></div><div class=item-after><div class="chip bg-pink" style="height: 22px;"><div class=chip-label>';
          r += c(ctx_2.session.Type, ctx_2);
          r += '</div></div></div></li></ul></div></div></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="container request-session-inner"><div class="main-content no-margin"><div class=booking-summary-block><div class="block mr-0 row"><div class=col-100><ul class="pd-0 mr-0 list-inline text-align-center time-block"><li><button class="button button-raised time-slot" style="min-width: 120px;" data-schedule=RESCHEDULE @click=onScheduleSelect>Reschedule</button></li><li><button class="button button-raised time-slot" style="width: 120px;" data-schedule=CANCEL @click=onScheduleSelect>Cancel</button></li></ul></div></div></div><div class="block no-padding no-margin"><!-- <p class="text-align-center" style="color: #777777; font-weight: 700;">Enter notes</p> --><div class=row><div class="col-100 book-time-block hide"><button class="button lh-15 t-normal select-date" style="text-transform: unset; line-height: 15px;">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.dispDate, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="f-10 date-title">Prefered Time</span>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="f-12 date-title">Select prefered time</span>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<br><span class="f-12 selected-date">';
      r += c(ctx_1.dispDate, ctx_1);
      r += '</span></button></div></div></div><div class="block no-padding"><p class=text-align-center style="color: #777777; font-weight: 700;">Enter notes</p><div class="list no-hairlines mr-0"><ul class=bg-none><li class="item-content item-input"><div class=item-inner><div class="item-input-wrap input-text-area"><textarea type=text name=note placeholder="Enter note" @input=onInput style="border: 1px solid lightgray;\r\n                                                padding: 5px 8px;\r\n                                                border-radius: 5px;"></textarea> <span class=input-clear-button></span></div></div></li></ul></div></div><div class="block no-margin no-padding"><div class=session-footer style="position: fixed; bottom: 0; width: 100%; left: 0; right: 0;">';
      r += Template7Helpers.js_if.call(ctx_1, "this.payload && this.payload['type'] && (this.payload['type'] === 'CANCEL' || (this.payload['type'] === 'RESCHEDULE' && this.payload['date'] && this.payload['schedule']))", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a class="button button-round book-app-btn" @click=onRequestSessionClick>Submit Request</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    