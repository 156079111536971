
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    var doctor = {};
    return doctor;
  },
  methods: {},
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$f7.dialog.preloader();
      $this.$f7.request.json($this.$app.data["endpoint"] + "/services/expert/" + $this.$route.params["id"] + "/about", {}, (data, status, xhr) => {
        $this.$f7.dialog.close();

        if (data["status"] === "success" && data["result"]) {
          $this["doctor"] = data["result"];
          $this.$update();
        }
      }, (xhr, status) => {
        $this.$f7.dialog.close();
      });
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '35df5e8793',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Profile</div></div></div><div class=page-content><div class=pattern-bg-fixed>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.doctor, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=container style="padding-bottom: 50px;"><div class="doctor-profile-page main-content"><div class="block no-margin"><div class=doctor-profile-header><div class="card no-shadow no-margin"><div class=card-content><div class="profile-pic elevation-3">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.ProfilePic, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=';
              r += c(ctx_3.doctor.ProfilePic, ctx_3);
              r += ' class=img-fluid alt=""> ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=./static/DefaultProfile.png class=img-fluid alt=""> ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=doctor-personal-details><ul class="pd-0 mr-0"><li><span class=title>';
          r += c(ctx_2.doctor.Salutation, ctx_2);
          r += ' ';
          r += c(ctx_2.doctor.Name, ctx_2);
          r += '</span></li><li>';
          r += c(ctx_2.doctor.Qualification, ctx_2);
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.Speciality, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' - ';
              r += c(ctx_3.doctor.Speciality, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</li><li><div class=row><div class=col><p class=fees>';
          r += c(ctx_2.doctor.Symbol, ctx_2);
          r += ' <span>';
          r += c(ctx_2.doctor.Fee, ctx_2);
          r += '</span></p></div><div class=col><p class=fees>';
          r += c(ctx_2.doctor.Duration, ctx_2);
          r += ' <span>mins</span></p></div></div></li></ul></div></div></div></div><div class=doctor-profile-content>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.Address, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<p class=info-row><i class="prfl-icon icon f7-icons if-not-md">placemark</i> <i class="prfl-icon icon material-icons if-md">room</i> ';
              r += c(ctx_3.doctor.Address.City, ctx_3);
              r += '</p>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<h4 class=title>ABOUT THE EXPERT</h4><p class=info-row><i class="prfl-icon icon f7-icons if-not-md">checkmark_shield</i> <i class="prfl-icon icon material-icons if-md">verified_user</i> ';
          r += c(ctx_2.doctor.Qualification, ctx_2);
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.Speciality, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' - ';
              r += c(ctx_3.doctor.Speciality, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.Languages, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<p class=info-row><i class="prfl-icon icon f7-icons if-not-md">bubble_left</i> <i class="prfl-icon icon material-icons if-md">language</i> Speaks <span>';
              r += c(ctx_3.doctor.Languages, ctx_3);
              r += '</span></p>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class=mt-2><p>';
          r += c(ctx_2.doctor.Description, ctx_2);
          r += '</p></div></div></div></div></div><div class=doctor-profile-footer style="position: fixed; bottom: 0; width: 100%; z-index: 1;"><a href=/teleconsult/requestsession/';
          r += c(ctx_2.doctor.id, ctx_2);
          r += ' class="button button-raised button-round w-100 btn-gredi-color">Request Session</a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    