
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import moment from 'moment';
export default {
  data: function () {
    var sleepSummary = this.$route.context;
    sleepSummary["SleepEfficiency"] = Math.floor(sleepSummary["SleepEfficiency"] || 0);
    return sleepSummary;
  },
  methods: {},
  on: {
    pageInit(e, page) {
      var $this = this; // $this.$update();
      // $this.qSwiper.on('slideChange', function (e) {
      //     var slidEl = $$(e.slides[e.realIndex]);
      //     if (slidEl.dataset() && slidEl.dataset()["next"]) {
      //         $this.$el.find(".next-btn").show();
      //     } else {
      //         $this.$el.find(".next-btn").hide();
      //     }
      //     console.log('slide changed');
      // });
      // $this.$f7.dialog.preloader();
      // $this.$f7.request.get($this.$app.data["endpoint"] + '/services/tool/' + $this.$route.context["_id"], {}, (data, status, xhr) => {
      //     $this.$f7.dialog.close();
      //     $this["Tool"] = (data["result"] ? (data["result"] || {}) : {});
      //     $this.$update();
      //     setTimeout(function () {
      //         $this.qSwiper = new Swiper('.swiper-container', {
      //             pagination: {
      //                 el: ".swiper-pagination",
      //                 type: 'progressbar',
      //             },
      //             spaceBetween: 15,
      //         });
      //     }, 300);
      // }, (xhr, status) => {
      //     $this.$f7.dialog.close();
      //     $this["Tool"] = {};
      //     $this.$update();
      // }, "json");
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: 'a33c512746',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Today sleep</div></div></div><!-- Scrollable page content--><div class="page-content no-padding-bottom expert-payment-booking"><div class=pattern-bg-fixed><div class=header-profile><div class=user-payment-prfl><div class=prfl-pic style="background-color: antiquewhite;"><p style="line-height: 90px;\r\n                                width: 90px;\r\n                                font-size: 40px;\r\n                                display: table;\r\n                                margin: 5px;"><span style="text-align: right;display: flow-root;">';
      r += c(ctx_1.SleepEfficiency, ctx_1);
      r += '</span> <span style="font-size: 16px;text-align: center;display: table-cell;">%</span></p></div></div></div><div class=container><div class=main-content><div class="payment-details-txt text-align-center"><!-- <h1 class="mr-0 amount"><span class="currency">&#8377;</span> 6,000</h1> --><p class="f-14 paid-to">Total sleep</p><p class=name><span style="font-size: 24px;">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.Sleep, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.Sleep.hh, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '0';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span> <span style="font-size: 16px; color: #3f3c3c;">h</span> <span style="font-size: 24px;">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.Sleep, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.Sleep.mm, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '0';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span> <span style="font-size: 16px; color: #3f3c3c;">m</span></p><span class=trans-txt>';
      r += c(ctx_1.SleepQuality, ctx_1);
      r += '</span></div><div class=expert-payment-footer><div class=payment-details-card><div class=card><!-- <div class="card-header">\r\n                                        <div class="payment-status">\r\n                                            <i class="f7-icons">checkmark_alt_circle_fill</i>\r\n                                        </div>\r\n                                        <div class="payment-detials">\r\n                                            <ul class="pd-0 mr-0">\r\n                                                <li><span class="paid-amt">Paid &#8377; 350.00</span></li>\r\n                                                <li><span>Today, 8:21AM</span></li>\r\n                                            </ul>                                    \r\n                                        </div>\r\n                                    </div> --><div class="card-content card-content-padding"><div class=transaction-details><div class=row><div class="col-50 left"><p>Sleep At</p><p class="number f-16">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.Sleep, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.Sleep.sleptAt, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '--';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p></div><div class=col-50><p>Wake up At</p><p class="number f-16">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.Sleep, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.Sleep.wakeupAt, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '--';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p></div></div><div class=row><div class="col-50 left"><p>Break in sleep</p><p class=number><span class=f-16>';
      r += Template7Helpers.js_if.call(ctx_1, "this['Break in sleep']", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js.call(ctx_2, "this['Break in sleep']['duration']", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '0';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span> <span style="color: #3f3c3c;">m</span></p></div><div class=col-50><p>Total break</p><p class=number><span class=f-16>';
      r += Template7Helpers.js_if.call(ctx_1, "this['Break in sleep']", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.js.call(ctx_2, "this['Break in sleep']['frequency']", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '0';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span> <span style="color: #3f3c3c;">';
      r += Template7Helpers.js_if.call(ctx_1, "this['Break in sleep'] && this['Break in sleep']['frequency'] && this['Break in sleep']['frequency'] > 1", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'times';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'time';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span></p></div></div><div class=row><div class="col-50 left"><p>Fell Asleep in</p><p class=number><span class=f-16>';
      r += Template7Helpers.js.call(ctx_1, "this['Fell to sleep']", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span> <span style="color: #3f3c3c;">m</span></p></div><div class=col-50><p>Wake up state</p><p class=number><span class=f-16>';
      r += Template7Helpers.js.call(ctx_1, "this['Wake up state']", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span></p></div></div></div><div class="share-receipt-block text-align-center"><a href=/activity/sleep/analytics class="button button-fill button-round share-receipt-btn">Continue</a></div></div></div></div><div class=text-align-center style="visibility: hidden;"><p class=bottom-txt>Payments may take up to 3 working days to be<br>reflected in your account<br></p><p class=no-margin><img src=https://web.moxie.one/images/web_hi_res_5121.png height=50 width=50 class=img-fluid style="margin-top: 0.5rem;"></p></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    