import $$ from 'dom7';
import moment from 'moment';
import Chart from 'chart.js';
export default {
  data: function () {
    var data = {
      isPDSQData: true,
      lables: this.$route.context["Lables"],
      data: this.$route.context["Data"]
    };
    return data;
  },
  methods: {},
  on: {
    pageInit(e, page) {
      var $this = this;
      PDSQRedarChart($this["data"], $this["lables"]);

      function PDSQRedarChart(allData, lab) {
        const data = {
          labels: lab,
          datasets: [{
            label: 'PDSQ Result',
            data: allData,
            fill: true,
            // backgroundColor: 'rgba(255, 99, 132, 0.4)',
            backgroundColor: 'rgba(255, 99, 132, 0.4)',
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
          }]
        };
        const config = {
          type: 'radar',
          data: data,
          options: {
            elements: {
              line: {
                borderWidth: 3
              }
            },
            scales: {
              r: {
                suggestedMin: 0,
                suggestedMax: 100
              }
            },
            responsive: true
          }
        };
        const myLineChart = new Chart($this.$el.find("#container"), config);
      }

      ;
    }

  },
  id: 'ce7199c591',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>PDSQ Analytics</div></div></div><style>/* #container {\r\n                height: auto !important;\r\n                width: inherit !important;\r\n            } */</style><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class="expert-availability container"><div class="row justify-content-center"><div class="col-100 medium-80"><div class=chart-container style="position: relative; height:40vh; width:80vw"><canvas id=container></canvas></div><!-- <figure class="highcharts-figure" style="margin: 0; height: 80vh; width: 100%;">\r\n                                <canvas id="container" width="500"></canvas>\r\n                            </figure> --></div></div><div class="block no-margin no-padding"><div class=session-footer style="position: fixed; bottom: 0; width: 100%; left: 0; right: 0;"><a href=/expertAvailability/MentalHealth/40cf06d5-b78b-40a8-8063-551adf6ecf5b class="button button-round book-app-btn">Book Appointment</a></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
            /* #container {
                height: auto !important;
                width: inherit !important;
            } */
        `,
  styleScoped: false
};