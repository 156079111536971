export default {
  id: 'bd94124299',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Sleep</div></div></div><div class=page-content><div class=pattern-bg-fixed><div class="activities-track-sleep main-content"><div class=container><div class="row justify-content-center"><div class="col-100 large-70"><div class="block no-margin"><div class=card><div class="card-content card-content-padding"><div class=row><div class=col-70><p>Did you take your<br>medications?</p></div><div class="col-30 text-align-right align-self-center"><label class="toggle toggle-init color-black"><input type=checkbox checked=checked> <span class=toggle-icon></span></label></div></div></div></div><div class=card><div class=card-content-padding><div class=row><div class="col text-align-center"><div class=mb-1><img src=https://www.flaticon.com/svg/static/icons/svg/1507/1507409.svg width=50 height=50 alt=""></div><p class="mr-0 f-12">Sleep at</p><p class="mr-0 f-18 bold">04:00 PM</p></div><div class="col text-align-center"><div class=mb-1><img src=https://www.flaticon.com/svg/static/icons/svg/760/760644.svg height=50 width=50 alt=""></div><p class="mr-0 f-12">Wokeup at</p><p class="mr-0 f-18 bold">04:00 PM</p></div></div></div></div><div class="card sleep-duration"><div class="card-content card-content-padding"><div class="left text-align-center"><img src=https://www.flaticon.com/svg/static/icons/svg/3587/3587086.svg width=50 height=50 alt=""></div><div class="right align-self-center"><p class="mr-0 f-12">Sleep Duration</p><p class="mr-0 f-18 bold">0 Hours 0 Mins</p></div></div></div></div><div class="block float-right select-emotion-footer"><button class="button button-fill button-round color-gray button-100">Next</button></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};