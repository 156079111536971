
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
export default {
  data: function () {
    var areas = [];
    return areas;
  },
  methods: {},
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$f7.dialog.preloader();
      $this.$f7.request.get($this.$app.data["endpoint"] + '/services/areas', {}, (data, status, xhr) => {
        $this.$f7.dialog.close();
        $this["areas"] = data["result"] || [];
        $this.$update();
        setTimeout(function () {
          $(page.$el).find(".issue-block .button").off("click").on("click", function (e) {
            $(page.$el).find(".issue-block .button").removeClass("active");
            $(this).addClass("active");
            $this.$router.navigate("/issuedomain/area", {
              context: ($this["areas"] || []).find((item, index) => {
                return item["Area"] === $(this).data("val");
              })
            });
          });
        }, 300);
      }, (xhr, status) => {
        $this.$f7.dialog.close();
      }, "json");
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '4b5d24d746',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Issue Domain</div></div></div><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class=issue-domain-area><div class="issue-domain-header text-align-center"><h2 class=font-alternate>How can we help?</h2></div><div class="mt-3 issue-domain-content text-align-center"><div class=issue-domain-middle><h3 class=font-alternate>I would like to work on</h3><ul class="pd-0 list-inline issue-block">';
      r += Template7Helpers.each.call(ctx_1, ctx_1.areas, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><button class="button button-outline" data-val=';
          r += c(ctx_2.Area, ctx_2);
          r += '>';
          r += c(ctx_2.Area, ctx_2);
          r += '</button></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class=issue-domain-footer style="position: fixed; bottom: 0; width: 100%;"><a href=/pdsq style="color: #000000;"><div class=issue-domain-img><img src=./static/trouble.png class=img-fluid alt=""></div><h3>I don\'t know<br>what is <b>troubling me</b></h3></a></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    