
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
import moment from 'moment';
import utility from '../js/utility';
export default {
  data: function () {
    var $selectExpert;
    return {
      request: null,
      experts: [],
      payload: {
        type: "Video",
        payment: "Paid"
      }
    };
  },
  methods: {
    onSlotSelectClick(e) {
      var $el = $$(e.currentTarget);
      this.$el.find(".schedule-slots .time-slot").removeClass("selected-time");

      if (this.payload["slot"] === $el.data("slot")) {
        this.payload["slot"] = null;
        this.$update();
      } else {
        this.payload["slot"] = $el.data("slot");
        $el.addClass("selected-time");
        this.$update();
        this.slectionDecision(true);
      }
    },

    onUpdateSessionClick(e) {
      var $this = this;
      $this.$f7.dialog.confirm("Update this session?", "Update Session", () => {
        utility.postRequest($this, "/consult/editsession/" + $this.$route.params["id"], $this.payload, (data, status, xhr, message) => {
          $this.$f7.dialog.close();

          if (data) {
            if (data["status"] === "success") {
              $this.$f7.dialog.alert("Success! Session has been booked.", "Session Update", function () {
                $this.$router.back("/teleconsult/requests", {
                  ignoreCache: true,
                  force: true
                });
              });
            } else {
              $this.$f7.dialog.alert("Failed! " + data["error"], "Session Update");
            }
          } else {
            $this.$f7.dialog.alert("Failed! " + message || status, "Session Update");
          }
        });
      });
    },

    slectionDecision(slot, cSlot) {
      if (slot) {
        this.$el.find(".select-datetime").removeClass("selected-date");
        this.$el.find(".selected-datetime-lbl").css("font-size", "unset");
        this.$el.find(".selected-datetime-val").html("");
        this.$el.find(".select-date").addClass("selected-date");
        this.$el.find(".selected-date-lbl").css("font-size", "10px").html("Selected date");
      } else {
        this.$el.find(".select-date").removeClass("selected-date");
        this.$el.find(".selected-date-val").html("");
        this.$el.find(".selected-date-lbl").css("font-size", "unset").html("Select date");
        this.$el.find(".select-datetime").addClass("selected-date");
        this.$el.find(".selected-datetime-lbl").css("font-size", "10px");
      }
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.$f7.dialog.preloader();
      utility.getRequest($this, "/consult/request/" + $this.$route.params["request"], {}, (data, status, xhr, message) => {
        utility.getRequest($this, "/services/experts", {}, (data1, status1, xhr1, message1) => {
          $this.$f7.dialog.close();

          if (data) {
            if (data["status"] === "success" && data["result"]) {
              $this["request"] = data["result"];
              $this["payload"]["request"] = data["result"]["id"];
              $this["payload"]["doctor"] = data["result"]["Doctor"];
              $this["payload"]["patient"] = data["result"]["Patient"];
              $this["payload"]["date"] = data["result"]["Date"];
              if (data1) $this["experts"] = data1["result"] || [];
              setTimeout(() => {
                utility.initCalendar($this, page.$el.find('.select-date'), function (cal) {
                  $this["payload"]["date"] = moment(cal.value[0]).startOf('day').format();
                  $this["payload"]["slot"] = null;
                  $this["request"]["customslot"] = null;
                  cal.$inputEl.find(".selected-date-val").html(moment(cal.value[0]).startOf('day').format("ddd, DD MMM, YY"));
                  $this.slectionDecision(true);

                  if ($this["payload"]["doctor"]) {
                    $this.$f7.dialog.preloader();
                    page.$el.find(".schedule-slots .time-slot").removeClass("selected-time");
                    getSlot(moment(cal.value[0]).format("DD-MM-YY"));
                  }

                  $this.$update();
                });
                utility.initCalendar($this, page.$el.find('.select-datetime'), function (cal) {
                  $this["payload"]["date"] = moment(cal.value[0]).startOf('day').format();
                  $this["request"]["slots"] = [];
                  $this["request"]["customslot"] = $this["payload"]["slot"] = moment(cal.value[0]).format("HH:mm");
                  cal.$inputEl.addClass("button-fill");
                  cal.$inputEl.find(".selected-datetime-val").html(moment(cal.value[0]).format("ddd, DD MMM, YY"));
                  $this.slectionDecision(false, true);
                  $this.$update();
                }, {
                  timePicker: true,
                  timePickerPlaceholder: "Select time",
                  closeOnSelect: false
                });
                initSmartSelect(function (sm) {
                  console.log("Smart select closed.");
                  $this.payload['doctor'] = sm.getValue() !== "-1" ? sm.getValue() : null;
                  getSlot(moment(data["result"]["Date"]).format("DD-MM-YY"));
                  $this.$update();
                }, $this.payload['doctor']);
                bindEvent();
              }, 200);
              if ($this["payload"]["doctor"]) getSlot(moment(data["result"]["Date"]).format("DD-MM-YY"));
            }
          }

          $this.$update();
        });
      });

      function initSmartSelect(callBack, value) {
        $this.$selectExpert = $this.$f7.smartSelect.create({
          el: ".expert-smart-select",
          pageTitle: "Select expert",
          valueEl: ".expert-smart-select .item-subtitle",
          closeOnSelect: true,
          scrollToSelectedItem: true,
          url: "expert/select",
          cssClass: "no-toolbar",
          on: {
            beforeOpen: callBack,
            opened: callBack,
            closed: callBack
          }
        });
        if (value) $this.$selectExpert.setValue(value);
      }

      ;

      function getSlot(date, callBack) {
        utility.getRequest($this, "/services/expert/" + $this["payload"]["doctor"] + "/slots/" + date, {}, (data, status, xhr, message) => {
          $this.$f7.dialog.close();

          if (data && data["status"] === "success" && data["result"]) {
            $this["request"]["slots"] = data["result"]["Slots"] || [];
            $this.$update();
          }

          if (callBack) callBack();
        });
      }

      ;

      function bindEvent() {// page.$el.find(".pmt-status-block .pmt-status").off("click").on("click", (e) => {
        //     page.$el.find(".pmt-status-block .pmt-status").removeClass("chip-outline color-red color-green active").addClass("chip-outline color-red");
        //     $$(e.currentTarget).removeClass("chip-outline color-red").addClass("color-green active");
        // });
      }

      ;
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: 'd8d262fef5',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class=navbar-inner><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Edit session</div></div></div><style>.chip {\r\n                margin: 0 3px;\r\n            }</style><div class=page-content><div class=pattern-bg-fixed><div class="content-block book-session-inner">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.request, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=row><div class=medium-15></div><div class="col-100 medium-70"><div class=row><div class="col-100 list mr-0"><ul><li><a class="item-link smart-select expert-smart-select"><select class=expert-select name=expert-select><option value=-1>Select an expert</option>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.experts, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "../this.request.Doctor === this.id", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<option value=';
                  r += c(ctx_4.id, ctx_4);
                  r += ' selected=selected>';
                  r += c(ctx_4.Salutation, ctx_4);
                  r += ' ';
                  r += c(ctx_4.Name, ctx_4);
                  r += '</option>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<option value=';
                  r += c(ctx_4.id, ctx_4);
                  r += '>';
                  r += c(ctx_4.Salutation, ctx_4);
                  r += ' ';
                  r += c(ctx_4.Name, ctx_4);
                  r += '</option>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</select><div class=item-content><div class=item-inner><div class=item-title><div class=item-header>Select/Swith expert</div><div class=item-subtitle>select an expert</div></div></div></div></a></li><li><a class=item-content><!-- <div class="item-media"><i class="icon icon-f7"></i></div> --><div class=item-inner><div class=item-title><div class=item-header>User</div>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.request.PatientDetails, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.request.PatientDetails.Salutation, ctx_3);
              r += ' ';
              r += c(ctx_3.request.PatientDetails.Name, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'N/A';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><!-- <div class="item-after">Edit</div> --></div></a></li></ul></div>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.request["SessionDetails"], {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="col-100 medium-50"><div class="block-title mt-1">Session Schedule at</div><div class="block no-margin"><div class="chip pointer requested-date"><div class=chip-label>';
              r += c(ctx_3.request["SessionDetails"]["Date"], ctx_3);
              r += '</div></div><div class="chip pointer requested-date"><div class=chip-label>';
              r += c(ctx_3.request["SessionDetails"]["Time"], ctx_3);
              r += '</div></div></div></div><div class="col-100 medium-50">';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=col-100>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class="block-title mt-1">Requested for</div><div class="block no-margin"><div class="chip pointer requested-date"><div class=chip-label>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.request, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.request.RequestedDate, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><div class="chip pointer requested-date"><div class=chip-label>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.request, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.request.Schedule, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></div><div class=col-35><div class="block-title mt-1">Consult Type</div><div class="block no-margin consult-type"><div class="chip pointer color-green active" data-var=Video><div class=chip-label>Video</div></div></div></div><div class=col-65><div class="block-title mt-1">Payment Status</div><div class="block no-margin pmt-status-block"><div class="chip pointer pmt-status color-green active" data-var=PAID><div class=chip-label>Paid</div></div><!-- <div class="chip pointer pmt-status chip-outline color-red" data-var="UNPAID">\r\n                                            <div class="chip-label">Un-Paid</div>\r\n                                        </div> --></div></div></div><div class="block-title m-5">Appointment Slot</div><div class=row style="margin: 0 16px;"><p class=segmented><button class="button button-outline button-fill lh-15 t-normal select-date selected-date"><span class="f-10 selected-date-lbl">Selected Date</span><br><span class="f-12 selected-date-val">';
          r += c(ctx_2.request.RequestedDate, ctx_2);
          r += '</span></button></p><p class=segmented><button class="button button-outline lh-15 t-normal select-datetime"><span class=selected-datetime-lbl>Custom Slot</span><br><span class="f-12 selected-datetime-val"></span></button></p></div><div class="no-margin book-time-block" style="border-top: 1px solid lightgray;">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.request.slots, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<ul class="schedule-slots mr-0 list-inline text-align-center time-block" style="padding: 10px 0;">';
              r += Template7Helpers.each.call(ctx_3, ctx_3.request.slots, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><button class="button button-raised time-slot" data-slot=';
                  r += c(ctx_4, ctx_4);
                  r += ' @click=onSlotSelectClick>';
                  r += c(ctx_4, ctx_4);
                  r += '</button></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.request.customslot, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<ul class="schedule-slots mr-0 list-inline text-align-center time-block" style="padding: 10px 0;"><li><button class="button button-raised time-slot selected-time" data-slot=';
              r += c(ctx_3.request.customslot, ctx_3);
              r += '>';
              r += c(ctx_3.request.customslot, ctx_3);
              r += '</button></li></ul>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><div class=medium-15></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      r += Template7Helpers.js_if.call(ctx_1, "this.payload && this.payload.type && this.payload['payment'] && this.payload['doctor'] && this.payload['patient'] && this.payload['date'] && this.payload['slot']", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="toolbar toolbar-bottom tabbar-labels"><div class=toolbar-inner style="display: block;"><a class="link book-now" @click=onUpdateSessionClick style="font-weight: 600;">Update Now</a></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  style: `
            .chip {
                margin: 0 3px;
            }
        `,
  styleScoped: false
};
    