export default {
  id: '960e071a48',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Mood</div></div></div><div class=page-content><div class=pattern-bg-fixed><div class="activities-track-mood main-content"><div class=container><div class="row justify-content-center"><div class="col-100 large-70"><div class=header><p>Hi, Gaurav pandey</p><p>How are you?</p><div class="mood-shedule text-align-center mt-2"><div class=mood-shedule-inner><ul class="mr-0 pd-0"><li><span><i class="fa fa-calendar-o" aria-hidden=true></i> Wednesday, 30 September</span></li><li><span>03:59 PM</span></li></ul></div></div></div><div class="block float-right select-emotion-footer"><button class="button button-fill button-round button-100">Next</button></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};