export default {
  id: '42be53d551',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class="expert-payment-booking main-content"><div class=header-profile><div class=user-payment-prfl><div class=prfl-pic><img src=https://web.moxie.one/images/web_hi_res_5121.png height=100px width=100px class=img-fluid></div></div></div><div class=container><div class="payment-details-txt text-align-center"><h1 class="mr-0 amount"><span class=currency>&#8377;</span> 6,000</h1><p class="f-14 paid-to">Paid to</p><p class=name>Marti Valencia</p><span class=trans-txt>Transferred to home</span></div><div class=expert-payment-footer><div class=payment-details-card><div class=card><div class=card-header><div class=payment-status><i class=f7-icons>checkmark_alt_circle_fill</i></div><div class=payment-detials><ul class="pd-0 mr-0"><li><span class=paid-amt>Paid &#8377; 350.00</span></li><li><span>Today, 8:21AM</span></li></ul></div></div><div class="card-content card-content-padding"><div class=transaction-details><div class=row><div class="col-70 left"><p>UPI Transaction ID</p><p class=number>0987654321</p></div><div class=col-30><p>To</p><p class=number>****5789</p></div></div></div><div class="share-receipt-block text-align-center"><a href=# class="button button-fill button-round share-receipt-btn">Share receipt</a></div></div></div></div><div class=text-align-center><p class=bottom-txt>Payments may take up to 3 working days to be<br>reflected in your account<br></p><p class=no-margin><img src=https://web.moxie.one/images/web_hi_res_5121.png height=50 width=50 class=img-fluid style="margin-top: 0.5rem;"></p></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};