
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data: function () {
    return this.$route.context;
  },
  methods: {},
  on: {},

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: 'b149ee28b9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Assessment Lavels</div></div></div><!-- Scrollable page content--><div class=page-content><div class=pattern-bg-fixed><div class=main-content><div class="expert-availability container"><div class=anxiety-result><div class="block no-margin text-align-center"><h2>Your ';
      r += c(ctx_1.ProblemDomain, ctx_1);
      r += ' Result</h2><div class=anxiety-result-bar><span class=count>';
      r += c(ctx_1.Score, ctx_1);
      r += '</span><div class=progressbar data-progress=100><span class=progress></span></div></div><p class="bold mb-1" style="color: #f27e14;">';
      r += c(ctx_1.Option, ctx_1);
      r += '</p><p>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.Desc, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.Desc, ctx_2);
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' People who score like you experience several symptoms of anxiety, such as uncontrollable worring, lack of focus and even muscle tension. You might get overwhelmed frequently or feel out of control. You might also often avoid difficult situations in order to feel less anxious. ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p><p>These concerns need to be addressed, as they seem<br>to be getting in the way of your everday life.</p><!-- <p class="mt-3"><a href="/expertAvailability/MentalHealth/40cf06d5-b78b-40a8-8063-551adf6ecf5b" class="button button-fill button-round button-raised button-200" style="background-color: #eb8e86;">Book Appointment</a></p> --></div></div><div class="block no-margin no-padding"><div class=session-footer style="position: fixed; bottom: 0; width: 100%; left: 0; right: 0;"><a href=/expertAvailability/MentalHealth/40cf06d5-b78b-40a8-8063-551adf6ecf5b class="button button-round book-app-btn">Book Appointment</a></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .progressbar {
        height: 8px;
        border-radius: 15px;
    }

    .progress {    
        color: white;
        text-align: center;
        line-height: 5px;
        font-size: 10px;
        font-family: "Segoe UI";
        animation-direction: reverse;
        background: #e5405e;

        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #00cc99 0%, #404040 50%, #d71b18 100%);
    }
`,
  styleScoped: false
};
    