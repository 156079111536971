
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import Swiper, { Navigation, Pagination } from 'swiper';
export default {
  data: function () {
    var pdsq = [],
        qSwiper,
        answers = [];
    return pdsq;
  },
  methods: {
    onAnswerClick(e) {
      var $this = this;
      var data = e.target.dataset;
      $this.answers.push(e.target.dataset);

      if ($this.qSwiper.isEnd) {
        var finalResult = [];
        let radarChart = {
          Labels: [],
          Data: []
        };

        for (var i = 0; i < $this.answers.length; i++) {
          var resultIndex = finalResult.findIndex((val, index) => {
            return val["section"] === $this.answers[i]["section"];
          });

          if (resultIndex !== -1) {
            finalResult[resultIndex]["Score"] = finalResult[resultIndex]["Score"] + $this.answers[i]["score"];
            finalResult[resultIndex]["QA"].push({
              Question: $this.answers[i]["srno"],
              Answer: $this.answers[i]["score"]
            });
          } else {
            finalResult.push({
              Section: $this.answers[i]["section"],
              Score: $this.answers[i]["score"],
              QA: [{
                Question: $this.answers[i]["srno"],
                Answer: $this.answers[i]["score"]
              }]
            });
          }
        }

        let a = 0,
            b = 0,
            c = 0,
            d = 0,
            e = 0,
            f = 0,
            g = 0,
            h = 0,
            total = 0;

        for (var j = 0; j < finalResult.length; j++) {
          if (finalResult[j]["Section"] == "A") {
            a += parseInt(finalResult[j]["Score"]);
          }

          if (finalResult[j]["Section"] == "B") {
            b += parseInt(finalResult[j]["Score"]);
          }

          if (finalResult[j]["Section"] == "C") {
            c += parseInt(finalResult[j]["Score"]);
          }

          if (finalResult[j]["Section"] == "D") {
            d += parseInt(finalResult[j]["Score"]);
          }

          if (finalResult[j]["Section"] == "E") {
            e += parseInt(finalResult[j]["Score"]);
          }

          if (finalResult[j]["Section"] == "F") {
            f += parseInt(finalResult[j]["Score"]);
          }

          if (finalResult[j]["Section"] == "G") {
            g += parseInt(finalResult[j]["Score"]);
          }

          if (finalResult[j]["Section"] == "H") {
            h += parseInt(finalResult[j]["Score"]);
          }

          total += parseInt(finalResult[j]["Score"]);
        }

        radarChart["Labels"] = ["Section-A", "Section-B", "Section-C", "Section-D", "Section-E", "Section-F", "Section-G", "Section-H"];
        radarChart["Data"] = [a, b, c, d, e, f, g, h];

        if ($this.$root.user) {
          let payload = {
            Result: finalResult,
            Score: total,
            Patient: $this.$root.user["Id"] || "",
            ChartData: {
              Labels: radarChart["Labels"],
              Data: radarChart["Data"]
            },
            ToolDetails: $this["pdsq"][0],
            ToolName: "PDSQ",
            ToolId: $this["pdsq"][0]["_id"]
          };
          $this.$f7.dialog.preloader();
          $this.$f7.request.post($this.$app.data["endpoint"] + '/services/savePDSQResult/', {
            Payload: payload
          }, (data, status, xhr) => {
            $this.$f7.dialog.close();
            data = JSON.parse(data);

            if (data && data["result"] === "SUCCESS" && data["Data"]) {
              $this.$router.navigate("/pdsq/analytics/", {
                context: {
                  Data: radarChart["Data"],
                  Lables: radarChart["Labels"]
                }
              });
            }
          });
        } else {
          $this.$router.navigate("/pdsq/analytics/", {
            context: {
              Data: radarChart["Data"],
              Lables: radarChart["Labels"]
            }
          });
        }
      } else {
        $this.qSwiper.slideNext();
      }
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;
      $this.answers = [];
      Swiper.use([Navigation, Pagination]);
      $this.$f7.dialog.preloader();
      $this.$f7.request.get($this.$app.data["endpoint"] + '/services/pdsq/PDSQ', {}, (data, status, xhr) => {
        $this.$f7.dialog.close();
        $this["pdsq"] = data["result"] ? [data["result"]] : [];
        $this.$update();
        setTimeout(function () {
          $this.qSwiper = new Swiper('.swiper-container', {
            pagination: {
              el: ".swiper-pagination",
              type: 'progressbar'
            },
            spaceBetween: 15
          });
          $this.qSwiper = $this.$app.swiper.get(".swiper-container");
        }, 300);
      }, (xhr, status) => {
        $this.$f7.dialog.close();
        $this["pdsq"] = [];
        $this.$update();
      }, "json");
    }

  },

  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  id: '0251c55087',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<!-- <style>\r\n        .assessment-content .pdsq-question-block a {\r\n            border-color: #9b8fb7;\r\n            color: #555555;\r\n            padding: 1.2rem 0rem;\r\n            line-height: 0;\r\n            width: 90%;\r\n            margin: 0 auto;\r\n            text-transform: initial;\r\n        }\r\n    </style> --><div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>PDSQ</div></div></div><div class=page-content><div class="pattern-bg-fixed main-content"><div class=container><div class=assessment-content><div class="block no-margin"><div class=pdsq-health-assessment><div class=pdsq-header style="margin-bottom: 1rem;"><div class="list no-hairlines mr-0"><ul class=bg-none><li><div class=item-content><div class=item-media><img src=./static/heart.png alt=""></div><div class=item-inner><div class=item-title><h2 class=mr-0>Health<br>Assessment ';
      r += c(ctx_1.Id, ctx_1);
      r += '</h2></div></div></div></li></ul></div></div><!-- Slider container --><div class="swiper-container self-assessment-inner"><div class=swiper-pagination></div><div class=swiper-wrapper>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdsq, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.Sections, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.Questions, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="swiper-slide pdq-ques-block" data-uuid="\'';
                  r += c(ctx_3.Name, ctx_4);
                  r += '\'">';
                  r += Template7Helpers.if.call(ctx_4, ctx_3.GroupQuestion, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<p class=pdsq-selected-que>';
                      r += c(ctx_3.GroupQuestion, ctx_5);
                      r += '</p>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '<div class="dsq-question-block verticle-button-block"><p class="mt-1 mb-1 ques-area" data-srno=';
                  r += c(ctx_4.SrNo, ctx_4);
                  r += '><b>';
                  r += c(ctx_4.SrNo, ctx_4);
                  r += '. </b>';
                  r += c(ctx_4.Text, ctx_4);
                  r += '</p><ul class=pdsq-question-block style="padding-left: 0;">';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.AnswerChoices, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li><button class="button button-outline ans-opt" data-section=';
                      r += c(ctx_3.Name, ctx_5);
                      r += ' data-srno=';
                      r += c(ctx_4.SrNo, ctx_5);
                      r += ' data-score=';
                      r += c(ctx_5.Score, ctx_5);
                      r += ' @click=onAnswerClick>';
                      r += c(ctx_5.Answer, ctx_5);
                      r += '</button></li>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</ul></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
        .assessment-content .pdsq-question-block a {
            border-color: #9b8fb7;
            color: #555555;
            padding: 1.2rem 0rem;
            line-height: 0;
            width: 90%;
            margin: 0 auto;
            text-transform: initial;
        }
    `,
  styleScoped: false
};
    