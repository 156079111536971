
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import $ from 'jquery';
import moment from 'moment';
import utility from '../js/utility';
export default {
  data: function () {
    var data = {
      doctor: this.$route.context["doctor"],
      patient: this.patient,
      schedule: {
        Date: this.$route.context["date"],
        Slot: this.$route.context["slot"],
        Duration: "30 mins",
        Mode: 'Video'
      },
      days: [],
      slots: ["09:00", "09:30", "10:00", "10:30"]
    };
    return data;
  },
  methods: {
    bookAppointment(e) {
      let $this = this;
      let payload = {
        doctor: $this["doctor"]["id"],
        patient: $this["patient"]["Id"],
        date: moment($this["schedule"]["Date"]).format(),
        slot: $this["schedule"]["Slot"],
        NEW: true,
        type: $this["schedule"]["Mode"]
      };
      $this.$f7.dialog.preloader();
      utility.postRequest($this, "/consult/booksession/" + payload["doctor"], payload, (data, status, xhr, message) => {
        $this.$f7.dialog.close();

        if (data) {
          if (data["status"] === "success") {
            $this[""] = data;
            localStorage.setItem("SuccessAppointment", JSON.stringify(data["result"]));
            setTimeout(() => {
              let newpopup = $this.$f7.popup.create({
                content: `
                                    <div class="popup" id="popup">
                                        <div class="view view-init" data-name="login" data-url="/success-messsage"></div>
                                    </div>
                                    `.trim(),
                on: {
                  closed: e => {
                    $this.$app.views.main.router.navigate("/teleconsult/home");
                  }
                }
              });
              newpopup.open();
            }, 300);
          } else {
            $this.$f7.dialog.alert("Failed! " + data["result"], "Session Booking");
          }
        } else {
          $this.$f7.dialog.alert("Failed! " + message || status, "Session Booking");
        }
      });
    },

    clickCopyButton() {
      console.log('hi');
    },

    updatePatientDetails() {
      console.log('hi');
    }

  },
  on: {
    pageInit(e, page) {
      var $this = this;

      if ($this.$root.user.Name === "Hi User") {
        $this.$el.find('.edit-patient-details').removeClass("hide");
        $this.$el.find('.note-padding-css').removeClass("hide");
      } else {
        $this.$el.find('.edit-patient-details').addClass("hide");
        $this.$el.find('.note-padding-css').addClass("hide");
      }
    }

  },
  id: '8b2750f287',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-toolbar"><div class="navbar top-navbar"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class=f7-icons>chevron_left</i> <span class=if-not-md>Back</span></a></div><div class=title>Booking Summary</div></div></div><!-- Scrollable page content--><div class="page-content no-padding-bottom"><div class=pattern-bg-fixed><div class=container><div class="expert-availability-payment main-content"><div class=top-header><div class=top-card><div class=card><div class=card-content><div class=doc-small-card><div class=doc-avtar>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.doctor.ProfilePic, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <img src=';
          r += c(ctx_2.doctor.ProfilePic, ctx_2);
          r += ' class=img-fluid alt=""> ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <img src=../static/DefaultProfile.png class=img-fluid alt=""> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=doc-prfl-right><ul class="pd-0 mr-0"><li><span class=title>';
      r += c(ctx_1.doctor.Name, ctx_1);
      r += '</span></li><li>';
      r += c(ctx_1.doctor.Qualification, ctx_1);
      r += Template7Helpers.if.call(ctx_1, ctx_1.doctor.Speciality, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' - ';
          r += c(ctx_2.doctor.Speciality, ctx_2);
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += Template7Helpers.if.call(ctx_1, ctx_1.doctor.Experience, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ', ';
          r += c(ctx_2.doctor.Experience, ctx_2);
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li><li>Speaks ';
      r += c(ctx_1.doctor.Languages, ctx_1);
      r += '</li></ul></div></div></div></div></div></div><!-- <div class="card">\r\n                            <div class="card-content">\r\n                                <div class="doc-small-card">\r\n                                    <div class="doc-avtar">\r\n                                        <img src="../static/DefaultProfile.png" class="img-fluid" alt="">\r\n                                    </div>\r\n                                    <div class="doc-prfl-right">\r\n                                        <div class="row justify-content-center">\r\n                                            <div class="col-100 medium-40">\r\n                                                <span class="title"> Patient Name : </span>&nbsp; <span class="title text-color-orange">  ';
      r += c(ctx_1.patient.Name, ctx_1);
      r += '</span>\r\n                                            </div>\r\n                                            <div class="col-100 medium-40">\r\n                                                <span class="title"> Phone Number : </span>&nbsp; <span class="title text-color-orange">  ';
      r += c(ctx_1.patient.PhoneNo, ctx_1);
      r += '</span>\r\n                                            </div>\r\n                                            <div class="col-100 medium-20">\r\n                                                <button class="button button-fill button-round button-pink"\r\n                                @click="updatePatientDetails">Update</button>\r\n                                            </div>\r\n                                        </div>\r\n                                    </div>\r\n                                </div>\r\n                            </div>\r\n                        </div> --><div class=booking-summary-block style="padding: 10px 0 10px 0; background-color: #f5ecec;"><div class="block mr-0"><div class="row no-gap"><div class="col text-align-center"><p>Appointment for</p><p class=display-text-color>';
      r += c(ctx_1.$root.user.Name, ctx_1);
      r += '</p>&nbsp;&nbsp;<div class=edit-patient-details><a href=/profile/ >Update</a></div></div><div class="col text-align-center"><p>Phone Number</p><p class=display-text-color>';
      r += c(ctx_1.$root.user.PhoneNo, ctx_1);
      r += '</p></div><!-- <div class="col text-align-center">\r\n                                        <p>Gender</p>\r\n                                        <p>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.patient.Gender, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += c(ctx_2.patient.Gender, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' -- ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p>\r\n                                    </div>\r\n                                    <div class="col text-align-center">\r\n                                        <p>Age</p>\r\n                                        <p>';
      r += c(ctx_1.patient.DOB, ctx_1);
      r += ' ';
      r += ' -- ';
      r += '</p>\r\n                                    </div> --></div></div></div><p class=note-padding-css><b>Note : </b>Please update your name. <b>"Hi User"</b> is a default system generate name</p>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.schedule, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=booking-summary-block><h3>Booking Summary</h3><div class="block mr-0"><div class="row no-gap"><div class="col text-align-center"><p>Date</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Date, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Date, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div><div class="col text-align-center"><p>Time</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Slot, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Slot, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div><div class="col text-align-center"><p>Duration</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Duration, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Duration, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div><div class="col text-align-center"><p>Mode</p><p>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.schedule.Mode, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.schedule.Mode, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '--';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=package-details><div class=row><div class="col text-align-center"><div class=package-det-block><p class=title>Session</p><p>1</p></div></div><div class="col text-align-center"><div class="package-det-block amount"><p>Amount</p><p>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.doctor.Fee, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.doctor.IsFree, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <strike>&#8377; ';
              r += c(ctx_3.doctor.Fee, ctx_3);
              r += ' </strike>&nbsp; &#8377; 0 ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' &#8377; ';
              r += c(ctx_3.doctor.Fee, ctx_3);
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '1000.00';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p></div></div></div></div><div class=availability-payment-footer><div class="block no-padding no-margin"><!-- <p class="mb-1 text-align-center" style="color: #777777; font-weight: 700;">Fill in the details to proceed</p>\r\n                                <div class="list no-hairlines mr-0">\r\n                                    <ul class="bg-none">\r\n                                        <li class="item-content item-input">\r\n                                            <div class="item-inner">\r\n                                                <div class="item-input-wrap">\r\n                                                    <input type="text" placeholder="Name">\r\n                                                    <span class="input-clear-button"></span>\r\n                                                </div>\r\n                                            </div>\r\n                                        </li>\r\n                                        <li class="item-content item-input">\r\n                                            <div class="item-inner">\r\n                                                <div class="item-input-wrap">\r\n                                                    <input type="text" placeholder="Mobile">\r\n                                                    <span class="input-clear-button"></span>\r\n                                                </div>\r\n                                            </div>\r\n                                        </li>                                   \r\n                                    </ul>\r\n                                </div>   --><p></p><div class="block-footer mt-2"><button class="button button-fill button-round button-pink" @click=bookAppointment>Book Appointment</button></div><p></p></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    